import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { UserSerializer } from 'networking/serializers/user-serializer';
import { AllDataUser } from 'models/all-data-user';
import { UserTemplates } from 'models/user-templates';
import { User } from 'models/user';
import { LocalStorageApi } from 'helpers/local-storage';
import flagsmith from 'flagsmith';
import { TemplateAndQuestionsSerializer } from 'networking/serializers/templates-and-questions-serializer';
import { Template } from 'models/template';

class UserController {
  static addCSRFTokenHeaderAndInLocalStorage = (csrfToken: string) => {
    ApiService.setHeaders({ 'X-CSRF-Token': csrfToken });
    LocalStorageApi.set('csrfToken', csrfToken);
  };

  static addGuestTokenHeader = (guestToken: string) => {
    ApiService.setHeaders({ 'Guest-Token': guestToken });
    LocalStorageApi.set('guestToken', guestToken);
  };

  static removeGuestTokenHeader = () => {
    ApiService.setHeaders({ 'Guest-Token': '' });
    LocalStorageApi.remove('guestToken');
  };

  static deSerializeUtmProperty(
    utmProperty: UtmProperties,
  ): UtmPropertiesDeSerialized {
    return UserSerializer.deSerializeUtmProperty(utmProperty);
  }

  static async registerUser(user: UserSignUpSerialized) {
    const serializedSignUpUser = UserSerializer.serializeSignUp(user);

    const response = await ApiService.post<AllDataUserRaw>(
      API_ROUTES.SIGNUP,
      serializedSignUpUser,
    );

    const userData = UserSerializer.deSerializeAllDataUser(response.data);

    return new AllDataUser(userData);
  }

  static async registerTemporaryUser(user: UserSignUpType) {
    const serializedSignUpUser = UserSerializer.serializeSignUpTemporary(user);
    const response = await ApiService.post<AllDataUserRaw>(
      API_ROUTES.SIGNUP_TEMPORARY_USER,
      serializedSignUpUser,
    );

    const userData = UserSerializer.deSerializeAllDataUser(response.data);

    return new AllDataUser(userData);
  }

  static async signInUser(user: UserSignInSerialized) {
    const serializedSignInUser = UserSerializer.serializeSignIn(user);
    const response = await ApiService.post<AllDataUserRaw>(
      API_ROUTES.SIGNIN,
      serializedSignInUser,
    );

    const userData = UserSerializer.deSerializeAllDataUser(response.data);

    return new AllDataUser(userData);
  }

  static async signOutUser() {
    ApiService.setHeaders({ 'X-CSRF-Token': '' });
    LocalStorageApi.remove('csrfToken');
    LocalStorageApi.remove('is_impersonating');
    LocalStorageApi.remove('product_tour_displayed');
    LocalStorageApi.remove('album_menu_tour_displayed');
    LocalStorageApi.remove('top_menu_tour_displayed');
    LocalStorageApi.remove('onboarding_phase');
    return ApiService.delete<void>(API_ROUTES.SIGNOUT);
  }

  static async forgotPassword(email: string) {
    return ApiService.post<void>(API_ROUTES.FORGOTPASSWORD, { email });
  }

  static async resetPassword(data: ResetPasswordSerialized) {
    const serializedData = UserSerializer.serializeResetPassword(data);
    return ApiService.post<void>(API_ROUTES.RESETPASSWORD, serializedData);
  }

  static async me() {
    const allData = await ApiService.get<AllDataUserRaw>(API_ROUTES.ME);
    const data = UserSerializer.deSerializeAllDataUser(allData.data);
    if (flagsmith.identity !== data.user.email) {
      // avoid duplicate calls to identify
      flagsmith.identify(data.user.email);
    }

    UserController.addCSRFTokenHeaderAndInLocalStorage(data.csrfToken);

    return new AllDataUser(data);
  }

  static async update(data: UserType) {
    const serializedData = UserSerializer.serializeUser(data);
    const response = await ApiService.put<UserRaw>(
      API_ROUTES.ME,
      serializedData,
    );
    const userData = UserSerializer.deSerializeUser(response.data);

    return new User(userData);
  }

  static async verifyUserEmail(data: VerifyEmailSerialized) {
    const serializedVerificationEmail =
      UserSerializer.serializeVerificationEmail(data);
    const response = await ApiService.post<AllDataUserRaw>(
      API_ROUTES.VERIFYEMAIL,
      serializedVerificationEmail,
    );
    const userData = UserSerializer.deSerializeAllDataUser(response.data);

    return new AllDataUser(userData);
  }

  static async resendUserEmail() {
    return ApiService.post<void>(API_ROUTES.RESENDEMAIL);
  }

  static async changePassword(data: ChangePasswordRequestType) {
    const serializedChangePassword =
      UserSerializer.serializeChangePassword(data);
    return ApiService.put(API_ROUTES.CHANGEPASSWORD, serializedChangePassword);
  }

  static async addPaymentMethod(data: AddPaymentMethodRequestType) {
    const serializedAddPaymentMethod =
      UserSerializer.serializeAddPaymentMethod(data);

    const userRawResponse = await ApiService.post<UserRaw>(
      API_ROUTES.ADD_PAYMENT_METHOD,
      serializedAddPaymentMethod,
    );

    const deSerializedUser = UserSerializer.deSerializeUser(
      userRawResponse.data,
    );

    return new User(deSerializedUser);
  }

  static async getCustomTemplates() {
    const response = await ApiService.get<PreBuiltQuestionsRaw[]>(
      API_ROUTES.GET_CUSTOM_TEMPLATES,
    );

    const deSerializedTemplates =
      TemplateAndQuestionsSerializer.deSerializeTemplates(response.data);

    return new UserTemplates({ templates: deSerializedTemplates });
  }

  static async addCustomTemplates() {
    const response = await ApiService.post<PreBuiltQuestionsRaw>(
      API_ROUTES.ADD_CUSTOM_TEMPLATES,
      { template: {} },
    );

    const deSerializedTemplate =
      TemplateAndQuestionsSerializer.deserializeTemplate(response.data);

    return new Template(deSerializedTemplate);
  }

  static async editCustomTemplate(requestData: EditTemplateRequestType) {
    const serializedTemplate =
      TemplateAndQuestionsSerializer.serializeEditTemplate(requestData);

    const response = await ApiService.put<PreBuiltQuestionsRaw>(
      API_ROUTES.MODIFY_CUSTOM_TEMPLATES(+requestData.template.id),
      serializedTemplate,
    );

    const deSerializedTemplate =
      TemplateAndQuestionsSerializer.deserializeTemplate(response.data);

    return new Template(deSerializedTemplate);
  }

  static async deleteCustomTemplate(templateId: number) {
    return ApiService.delete<PreBuiltQuestionsRaw>(
      API_ROUTES.MODIFY_CUSTOM_TEMPLATES(templateId),
    );
  }

  static async createTemporaryUser() {
    const response = await ApiService.post<AllDataUserRaw>(
      API_ROUTES.CREATE_TEMPORARY_USER,
    );

    const userData = UserSerializer.deSerializeAllDataUser(response.data);

    return new AllDataUser(userData);
  }

  static async isUserActive(email: string) {
    const response = await ApiService.get<{ active: boolean }>(
      API_ROUTES.USER_ACTIVE,
      { email },
    );

    return response.data.active;
  }
}
export { UserController };
