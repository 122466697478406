import { AppContext } from 'context';
import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import { LocalStorageApi } from 'helpers/local-storage';
import { useContext, useEffect } from 'react';

export const useTriggerOnboarding = () => {
  const {
    state: {
      data: {
        user: { id, verified, firstVisitDisplayed },
      },
    },
  } = useContext(AppContext);
  const {
    state: { onboardingPhase },
    dispatch: onboardingDispatch,
  } = useContext(OnboardingContext);

  useEffect(() => {
    if (id > 0 && verified && !firstVisitDisplayed && !onboardingPhase) {
      onboardingDispatch({
        type: onboardingActions.SET_ONBOARDING_PHASE,
        onboardingPhase: OnboardingPhase.productTour,
      });
      LocalStorageApi.set('onboarding_phase', OnboardingPhase.productTour);
    }
  }, [id, verified, firstVisitDisplayed]);

  return {};
};
