import { Modal } from 'common/modal';
import React, { useContext } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { AppleIcon, GooglePlayIcon } from 'assets/icons';
import { storeLinks } from 'config/constants';
import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { LocalStorageApi } from 'helpers/local-storage';
import styles from './download-the-app-modal.module.scss';

type DownloadTheAppModalProps = {};

const DownloadTheAppModal: React.FC<DownloadTheAppModalProps> = () => {
  const { dispatch } = useContext(OnboardingContext);

  return (
    <Modal contentClassName={styles.container} disableManualClosing>
      <div className={styles.content}>
        <div className="text__heading4__primary50">Get the app!</div>
        <div className="text__body__regular__medium__textNeutral30">
          Record on the go! Download the Generational Story app to record
          anywhere you go.
        </div>
        <div className={styles.downloadAppButtons}>
          <Button
            className={styles.button}
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={() => window.open(storeLinks.appleStore, '_blank')}
          >
            <AppleIcon />
            Generational Story for iOS
          </Button>
          <Button
            className={styles.button}
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={() => window.open(storeLinks.androidStore, '_blank')}
          >
            <GooglePlayIcon />
            Generational Story for Android
          </Button>
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.button}
            onClick={() => {
              dispatch({ type: onboardingActions.SET_ONBOARDING_PHASE });
              LocalStorageApi.set('onboarding_phase', null);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { DownloadTheAppModal };
