import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { LocalStorageApi } from 'helpers/local-storage';
import { useContext, useEffect } from 'react';

export const useSetToursDisplayed = () => {
  const { dispatch: onboardingDispatch } = useContext(OnboardingContext);
  useEffect(() => {
    const productTourDisplayed = LocalStorageApi.get('product_tour_displayed');
    const albumMenuTourDisplayed = LocalStorageApi.get(
      'album_menu_tour_displayed',
    );
    const topMenuTourDisplayed = LocalStorageApi.get('top_menu_tour_displayed');
    const onboardingPhase = LocalStorageApi.get('onboarding_phase');
    onboardingDispatch({
      type: onboardingActions.SET_PRODUCT_TOUR_DISPLAYED,
      productTourDisplayed,
    });
    onboardingDispatch({
      type: onboardingActions.SET_ALBUM_MENU_TOUR_DISPLAYED,
      albumMenuTourDisplayed,
    });
    onboardingDispatch({
      type: onboardingActions.SET_TOP_MENU_TOUR_DISPLAYED,
      topMenuTourDisplayed,
    });
    onboardingDispatch({
      type: onboardingActions.SET_ONBOARDING_PHASE,
      onboardingPhase,
    });
  }, []);
};
