import { WebcamPreview } from 'pages/album-detail/components/video-call-waiting-room/webcam-preview';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { AlertTriangleIcon, CrossIcon, PlayIcon, StopIcon } from 'assets/icons';
import { useLoadSensorSelection } from 'hooks/use-load-sensor-selection';
import { WaitingRoomSensorSelection } from 'pages/album-detail/components/video-call-waiting-room/waiting-room-sensor-selection';
import { Toggle } from 'common/toggle';
import {
  formatRecordingTime,
  isFreeTrial,
  canUpgradeAlbum,
  isContributor,
  canBuyClips,
  userPermissionUpgrade,
  isTemporaryUser,
} from 'helpers/utils';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext, SensorSelectionContext, appActions } from 'context';
import { maxTimeClipRecordedInSeconds } from 'config/constants';
import startRecordingSound from 'assets/audios/start-recording-sound.mp3';
import endRecordingSound from 'assets/audios/end-recording-sound.mp3';
import { Modal } from 'common/modal';
import { Spinner } from 'common/spinner';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { RouteName, goToPage } from 'routes';
import { useSelfRecording } from 'hooks/use-self-recording';
import { SelfRecordingContext } from 'context/self-recording-context';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { LocalStorageApi } from 'helpers/local-storage';
import { NotificationObject } from 'models/notificationObject';
import {
  Breakpoints,
  NotificationType,
  SelfRecordingStatus,
} from 'common/enums';
import { useMediaQuery } from 'hooks/use-media-query';
import { BuyClipsProcess } from 'common/buy-clips-process';
import { InfoModal } from 'common/info-modal';
import { HelperText } from 'common/helper-text';
import { calculateRecordingModalInstructions } from 'common/sensor-permission-instructions/instructions';
import styles from './camera-record.module.scss';

type CameraRecordProps = {
  album: AlbumType;
  upgradeAction?: () => void;
  refetchAlbum: () => Promise<void>;
};

const CameraRecord: React.FC<CameraRecordProps> = ({
  album,
  upgradeAction,
  refetchAlbum,
}) => {
  const { state: selfRecordingState, dispatch } =
    useContext(SelfRecordingContext);
  const { state, dispatch: generalDispatch } = useContext(AppContext);
  const { subscriptionsScheme } = state;
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [timer, setTimer] = useState(0);
  const progressBarStyle = {
    width: `${(timer / maxTimeClipRecordedInSeconds) * 100}%`,
  };
  const maxQuestionsAllowed = album.maxClips;
  const {
    mediaRecorderState,
    countingTime,
    retryFailedRequests,
    closeAllTracks,
    createMediaRecorder,
  } = useSelfRecording();
  const [continueInNewClip, setContinueInNewClip] = useState(true);
  const endRecordingSoundRef = useRef<HTMLAudioElement>(null);
  const startRecordingSoundRef = useRef<HTMLAudioElement>(null);
  const [showModalUploading, setShowModalUploading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const {
    sensorSelection: {
      microphone: { error: microphoneError, enabled: micEnabled },
      camera: { error: cameraError, enabled: cameraEnabled },
    },
  } = useContext(SensorSelectionContext);
  useLoadSensorSelection();
  const guestToken = LocalStorageApi.get('guestToken');

  const [showToggleMessage, setShowToggleMessage] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showModalBuyClips, setShowModalBuyClips] = useState<boolean>(false);
  const [showMediaErrorModal, setShowMediaErrorModal] = useState(false);

  const [errorModalHeader, errorModalContent] =
    calculateRecordingModalInstructions(microphoneError, cameraError);

  const missingFunctionality = !!microphoneError || !!cameraError;

  let myInterval: NodeJS.Timeout;

  const canStartRecording = () => {
    const amountOfClips = album?.clips?.length || 0;
    return (
      cameraEnabled &&
      micEnabled &&
      amountOfClips + selfRecordingState.videosRecorded.length <
        maxQuestionsAllowed
    );
  };

  const showMediaErrorModalComponent = () => (
    <InfoModal
      className={styles.modalMediaError}
      title="Camera & Mic"
      content={
        <div className={styles.modalMediaErrorContent}>
          <HelperText
            icon={<AlertTriangleIcon className={styles.modalMediaErrorIcon} />}
            content={
              <span className="text__body__regular__large__textNeutral50">
                {errorModalHeader}
              </span>
            }
          />
          <p>{errorModalContent}</p>
        </div>
      }
      onClose={() => setShowMediaErrorModal(false)}
    />
  );

  const showNotificationButtons = (buttonStyle: ButtonStyle) => (
    <div className={styles.notificationButtons}>
      <Button
        buttonSize={ButtonSize.Small}
        buttonStyle={buttonStyle}
        onClick={upgradeAction}
      >
        Upgrade
      </Button>
      {canBuyClips(album, subscriptionsScheme) && (
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.GreyGhost}
          onClick={() => setShowModalBuyClips(true)}
        >
          Buy clips
        </Button>
      )}
    </div>
  );

  const checkShowMessage = () => {
    const contributor = isContributor(state.data.user, album);
    const amountOfClips = album?.clips?.length || 0;
    const isTrial = isFreeTrial(album);
    const isFull =
      selfRecordingState.videosRecorded.length + amountOfClips ===
      maxQuestionsAllowed;
    const isAlmostFull =
      maxQuestionsAllowed -
        (selfRecordingState.videosRecorded.length + amountOfClips) <=
      3;
    const fullTitle = isTrial
      ? 'Your trial album is full'
      : 'Your album is full';
    let fullMessage = '';
    if (isTrial) {
      fullMessage = `${fullMessage}${
        contributor
          ? 'Contact the album owner to upgrade their subscription to keep recording.'
          : 'Upgrade to a subscription to increase your limit.'
      }`;
    } else if (contributor) {
      fullMessage = `${fullMessage} Contact the album owner to purchase extra clips or upgrade their subscription for extra storage.`;
    } else {
      fullMessage = `${fullMessage}You’ve recorded ${
        selfRecordingState.videosRecorded.length + amountOfClips
      } out of ${maxQuestionsAllowed} clips. Get extra storage by purchasing extra clips${
        canUpgradeAlbum(album) && 'or upgrading your subscription'
      }.`;
    }

    const reachingLimitTitle = isTrial
      ? 'Your trial album is almost full'
      : 'Your album is almost full';
    let reachingLimitMessage = `You’ve recorded ${
      selfRecordingState.videosRecorded.length + amountOfClips
    } out of ${maxQuestionsAllowed} clips.`;
    if (isTrial) {
      reachingLimitMessage = `${reachingLimitMessage} Upgrade to a subscription to increase your limit.`;
    } else if (contributor) {
      reachingLimitMessage = `${reachingLimitMessage} Contact the album owner to purchase extra clips or upgrade their subscription for extra storage.`;
    } else {
      reachingLimitMessage = `${reachingLimitMessage} Get extra storage by purchasing extra clips${
        canUpgradeAlbum(album, subscriptionsScheme)
          ? ' or upgrading your subscription'
          : ''
      }.`;
    }

    if (
      maxQuestionsAllowed > 0 &&
      !showWarning &&
      userPermissionUpgrade(state.data.user)
    ) {
      if (isAlmostFull) {
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: reachingLimitTitle,
            message: reachingLimitMessage,
            type: NotificationType.Info,
            footer:
              !contributor && showNotificationButtons(ButtonStyle.PrimaryGhost),
            limitTimeMs: 1000 * 15,
          }),
        });
      }
      if (isFull) {
        setShowWarning(true);
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: fullTitle,
            message: fullMessage,
            type: NotificationType.Error,
            footer:
              !contributor &&
              showNotificationButtons(ButtonStyle.SecondaryGhost),
            limitTimeMs: 1000 * 15,
          }),
        });
      }
    }
  };

  const startRecord = async () => {
    startRecordingSoundRef.current?.play();
    const mediaRecorder = await createMediaRecorder();
    if (mediaRecorder) {
      mediaRecorder.start();
      dispatch({ type: selfRecordingActions.setClipNumber, clipNumber: 1 });
    }
  };

  const modalBuyClips = () => (
    <BuyClipsProcess
      onFinishProcess={() => setShowModalBuyClips(false)}
      album={album}
      refetchAlbum={refetchAlbum}
    />
  );

  useEffect(() => {
    setShowMediaErrorModal(missingFunctionality);
  }, [missingFunctionality]);

  useEffect(() => {
    dispatch({ type: selfRecordingActions.setReadyForNextStep });
  }, []);

  const stopRecord = () => {
    endRecordingSoundRef.current?.play();
    mediaRecorderState?.stop();
    dispatch({ type: selfRecordingActions.setClipNumber, clipNumber: 0 });
    checkShowMessage();
    setShowModalUploading(true);
  };

  useEffect(() => {
    if (showModalUploading && selfRecordingState.readyForNextStep) {
      setShowModalUploading(false);
      closeAllTracks();
      if (guestToken?.length) {
        goToPage(RouteName.ReviewVideosGuest, { guestToken });
      } else {
        goToPage(RouteName.ReviewVideos);
      }
    }
  }, [selfRecordingState]);

  useEffect(() => {
    checkShowMessage();
    return closeAllTracks;
  }, []);

  useEffect(() => {
    const processing = selfRecordingState.videosRecorded.some(
      (video) => video.status === SelfRecordingStatus.processing,
    );
    const haveErrors = selfRecordingState.videosRecorded.some(
      (video) => video.status === SelfRecordingStatus.error,
    );
    if (!processing && haveErrors) {
      setShowModalUploading(false);
      setShowErrorModal(true);
    }
  }, [selfRecordingState]);

  useEffect(() => {
    if (timer === maxTimeClipRecordedInSeconds - 30 && !continueInNewClip) {
      setShowToggleMessage(true);
    }
    if (timer === maxTimeClipRecordedInSeconds) {
      if (continueInNewClip && canStartRecording()) {
        mediaRecorderState?.stop();
        checkShowMessage();
        mediaRecorderState?.start();
        dispatch({
          type: selfRecordingActions.setClipNumber,
          clipNumber: selfRecordingState.clipNumber + 1,
        });
      } else {
        stopRecord();
        checkShowMessage();
      }
    }
  }, [timer, continueInNewClip]);

  useEffect(() => {
    if (countingTime) {
      myInterval = setInterval(() => {
        setTimer((t) => t + 1);
      }, 1000);
    }
    return () => {
      setTimer(0);
      clearInterval(myInterval);
    };
  }, [countingTime]);

  const showWaitingRoomModal = () => (
    <Modal
      disableManualClosing
      className={styles.waitingModal}
      contentClassName={styles.waitingModalContent}
    >
      <div className={styles.modalContent}>
        <Spinner className={globalStyles.mediumSpinner} />
        <div className={styles.messageAndButton}>
          <p className="text__body__regular__medium__textNeutral40">
            We’re processing your recording. Please do not close this tab.
          </p>
          <div className={styles.button}>
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.RedStroke}
              onClick={() => {
                setShowModalUploading(false);
                dispatch({
                  type: selfRecordingActions.deleteAllVideos,
                });
              }}
            >
              Cancel recording
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
  const errorModal = () => (
    <Modal
      disableManualClosing
      contentClassName={styles.errorContent}
      className={styles.waitingModal}
    >
      <div className={styles.errorModal}>
        <CrossIcon className={styles.crossIcon} />
        <div className={styles.messageAndButton}>
          <p className="text__body__regular__medium__textNeutral40">
            Oh no. Something went wrong. Please try again.
          </p>
          <div className={styles.button}>
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.RedGhost}
              onClick={() => {
                setShowErrorModal(false);
                dispatch({
                  type: selfRecordingActions.deleteAllVideos,
                });
              }}
            >
              Cancel recording
            </Button>
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.PrimaryFilled}
              onClick={() => {
                setShowErrorModal(false);
                retryFailedRequests();
                setShowModalUploading(true);
              }}
            >
              Retry
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const showMobileLayout = () => (
    <>
      <div className={styles.timeAndLine}>
        <span className="text__body__regular__medium__textNeutral40">
          {formatRecordingTime(timer)}
        </span>
        <div className={styles.progressBar}>
          <div className={styles.progress} style={progressBarStyle} />
        </div>
        <span className="text__body__regular__medium__textNeutral40">
          {formatRecordingTime(maxTimeClipRecordedInSeconds)}
        </span>
      </div>
      {selfRecordingState.isRecording ? (
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.SecondaryFilled}
          onClick={stopRecord}
          className={styles.recordButton}
        >
          <StopIcon />
          Stop Recording
        </Button>
      ) : (
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.PrimaryFilled}
          onClick={startRecord}
          disabled={!canStartRecording()}
          className={styles.recordButton}
          tooltipContent="Limit reached, available after sign up"
          tooltipPosition="top"
          tooltip={!canStartRecording() && isTemporaryUser(state.data.user)}
          tooltipId="camera-microphone-tooltip"
        >
          <PlayIcon />
          Start Recording
        </Button>
      )}
      <div className={styles.toggleAndText}>
        <Toggle
          id="switch"
          tooltipText="Your time is running out. Click on this toggle to continue recording in a new clip."
          checked={continueInNewClip}
          onChangeFn={() => {
            setContinueInNewClip(!continueInNewClip);
          }}
          showTooltip={showToggleMessage}
        />
        <span className="text__body__regular__medium__textNeutral30">
          Continue in new clip if time runs out
        </span>
      </div>
      <WaitingRoomSensorSelection />
    </>
  );

  return (
    <div className={styles.allPage}>
      {showModalBuyClips && modalBuyClips()}
      {showModalUploading &&
        !selfRecordingState.readyForNextStep &&
        showWaitingRoomModal()}
      {showErrorModal && errorModal()}
      {showMediaErrorModal && showMediaErrorModalComponent()}
      <WebcamPreview
        className={styles.camera}
        isRecording={selfRecordingState.isRecording}
        showButtons={false}
        clipNumber={selfRecordingState.clipNumber}
      />
      {isMobile ? (
        showMobileLayout()
      ) : (
        <div className={styles.recordSection}>
          <div className={styles.timeLineAndRecordButton}>
            <div className={styles.timeAndLine}>
              <span className="text__body__regular__medium__textNeutral40">
                {formatRecordingTime(timer)}
              </span>
              <div className={styles.progressBar}>
                <div className={styles.progress} style={progressBarStyle} />
              </div>
              <span className="text__body__regular__medium__textNeutral40">
                {formatRecordingTime(maxTimeClipRecordedInSeconds)}
              </span>
            </div>
            {selfRecordingState.isRecording ? (
              <Button
                buttonSize={ButtonSize.Medium}
                buttonStyle={ButtonStyle.SecondaryFilled}
                onClick={stopRecord}
                className={styles.recordButton}
              >
                <StopIcon />
                Stop Recording
              </Button>
            ) : (
              <Button
                buttonSize={ButtonSize.Medium}
                buttonStyle={ButtonStyle.PrimaryFilled}
                onClick={startRecord}
                disabled={!canStartRecording()}
                tooltipContent="Limit reached, available after sign up"
                tooltipPosition="top"
                tooltip={
                  !canStartRecording() && isTemporaryUser(state.data.user)
                }
                tooltipId="camera-microphone-tooltip"
              >
                <div className={styles.recordButton}>
                  <PlayIcon />
                  Start Recording
                </div>
              </Button>
            )}
          </div>
          <div className={styles.SensorsAndToggle}>
            <WaitingRoomSensorSelection />
            <div className={styles.toggleAndText}>
              <Toggle
                id="switch"
                tooltipText="Your time is running out. Click on this toggle to continue recording in a new clip."
                checked={continueInNewClip}
                onChangeFn={() => {
                  setContinueInNewClip(!continueInNewClip);
                }}
                showTooltip={showToggleMessage}
              />
              <span className="text__body__regular__medium__textNeutral30">
                Continue in new clip if time runs out
              </span>
            </div>
          </div>
        </div>
      )}
      <audio ref={startRecordingSoundRef} hidden>
        <source src={startRecordingSound} type="audio/mp3" />
        <track kind="captions" />
      </audio>
      <audio ref={endRecordingSoundRef} hidden>
        <source src={endRecordingSound} type="audio/mp3" />
        <track kind="captions" />
      </audio>
    </div>
  );
};
export { CameraRecord };
