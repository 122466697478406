import React, { RefObject, useContext, useState } from 'react';

import { goToPage, RouteName } from 'routes';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import {
  albumHasPublicClip,
  classnames,
  isReadOnlySubOrRestrictedAlbum,
  isRestrictedAlbum,
  isTemporaryUser,
} from 'helpers/utils';

import { PencilIcon, LinkIcon } from 'assets/icons';
import { CopyLink } from 'common/copy-link';
import { apiBaseURL } from 'config/constants';
import { getRouteFor } from 'routes/route-helpers';
import { trackCustomEvent } from 'helpers/analytics';
import { ModalEditAlbum } from 'common/modal-edit-album';
import { appActions, AppContext } from 'context';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import styles from './left-section.module.scss';

type LeftSectionProps = {
  user: UserType;
  currentAlbum?: AlbumType;
  activeAlbumRef?: RefObject<HTMLDivElement>;
  copyLinkRef?: RefObject<HTMLDivElement>;
};

const LeftSection: React.FC<LeftSectionProps> = ({
  user,
  currentAlbum,
  activeAlbumRef,
  copyLinkRef,
}) => {
  const { dispatch } = useContext(AppContext);
  const [showEditAlbumModal, setShowEditAlbumModal] = useState(false);

  const isMobileOrTablet = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const handleEditAlbumModal = () => {
    setShowEditAlbumModal(true);
    trackCustomEvent('startEditAlbum');
  };

  const handleSuccessEditAlbumModal = () => {
    setShowEditAlbumModal(false);
    dispatch({
      type: appActions.SET_REFETCH_ALBUM,
      refetchAlbum: true,
    });
    trackCustomEvent('confirmEditAlbum');
  };

  const showCompanyName = (company: string) => (
    <div className={styles.company}>
      <span className={styles.powered}> Managed by</span>
      &nbsp;
      <span className={styles.companyName}>{company}</span>
    </div>
  );

  const getCompanyName = () =>
    user.companyName ? showCompanyName(user.companyName) : null;

  const showAlbumInfo = (album: AlbumType) => (
    <div className={styles.albumInfoContainer}>
      <div
        className={classnames(
          'text__body__semi__bold__medium__textNeutral30',
          styles.album,
        )}
        ref={activeAlbumRef}
        id="active-album"
      >
        <div className={styles.leftSection}>
          <div className={styles.avatarContainer}>
            {album.coverThumbnailUrl ? (
              <img
                alt="album-cover"
                src={album.coverUrl}
                className={styles.avatar}
              />
            ) : (
              <Logo height="24px" width="24px" />
            )}
          </div>
          <span className={styles.title}>{album.title}</span>
          <button
            type="button"
            className={styles.editButton}
            disabled={isReadOnlySubOrRestrictedAlbum(album)}
            onClick={handleEditAlbumModal}
          >
            <PencilIcon />
          </button>
        </div>
        <button
          onClick={() => {
            goToPage(RouteName.Albums);
          }}
          type="button"
          className={classnames(
            styles.changeAlbumButton,
            'text__body__semi__bold__tiny__primary50',
          )}
        >
          {isMobileOrTablet ? 'Change' : 'Change album'}
        </button>
      </div>
      <div ref={copyLinkRef} id="copy-link">
        <CopyLink
          disabled={!albumHasPublicClip(album) || isRestrictedAlbum(album)}
          className={styles.linkOption}
          path={`${apiBaseURL}${getRouteFor(RouteName.AlbumDetailGuest, {
            id: album.id,
          })}`}
          showModalWarning={isTemporaryUser(user)}
          copyLinkCallback={() => {
            trackCustomEvent('copyLinkToAlbum');
          }}
        >
          <LinkIcon className={styles.linkIcon} />
          <div className="text__body__semi__bold__tiny__primary50">
            {' '}
            Copy link to album{' '}
          </div>
        </CopyLink>
      </div>
    </div>
  );

  const showContent = () => {
    if (isTemporaryUser(user)) {
      return (
        <div className={styles.logoAndTexts}>
          <Logo className={styles.logo} />
          <div
            className={classnames(styles.allText, {
              [styles.gsBiggerSize]: !user.companyName,
            })}
          >
            <div className={styles.gs}>
              <div className={styles.generational}>Generational</div>
              <div className={styles.story}>Story</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.container}>
        <button
          type="button"
          onClick={() => goToPage(RouteName.Albums)}
          className={styles.logoAndTexts}
        >
          <Logo className={styles.logo} />
          <div
            className={classnames(styles.allText, {
              [styles.gsBiggerSize]: !user.companyName,
            })}
          >
            <div className={styles.gs}>
              <div className={styles.generational}>Generational</div>
              <div className={styles.story}>Story</div>
            </div>
            {getCompanyName()}
          </div>
        </button>
        {!!currentAlbum && showAlbumInfo(currentAlbum)}
      </div>
    );
  };

  const showEditAlbumModalComponent = (album: AlbumType) => (
    <ModalEditAlbum
      onClose={() => setShowEditAlbumModal(false)}
      onSuccess={handleSuccessEditAlbumModal}
      album={album}
    />
  );

  return (
    <>
      {currentAlbum &&
        showEditAlbumModal &&
        showEditAlbumModalComponent(currentAlbum)}
      {showContent()}
    </>
  );
};

export { LeftSection };
