import React, { useContext, useEffect } from 'react';
import Webcam from 'react-webcam';
import { classnames } from 'helpers/utils';
import { NoVideoIcon } from 'assets/icons';

import { SensorToggleButton } from 'common/sensor-toggle-button';
import { Spinner } from 'common/spinner';
import { AudioAnalyser } from 'common/audio-analyser';
import { SensorSelectionContext } from 'context';
import { captureException } from 'sentry';
import { SensorSelectionActions } from 'context/sensor-selection-context/action-types';
import styles from './webcam-preview.module.scss';

type WebcamPreviewProps = {
  className?: string;
  isRecording?: boolean;
  showButtons?: boolean;
  clipNumber?: number;
};

const VIDEO_DIMENSIONS = {
  width: 1280,
  height: 720,
};

const WebcamPreview: React.FC<WebcamPreviewProps> = ({
  className = '',
  isRecording = false,
  showButtons = true,
  clipNumber,
}) => {
  const { sensorSelection, updateSensorSelection } = useContext(
    SensorSelectionContext,
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [mediaStream, setMediaStream] = React.useState<MediaStream | null>(
    null,
  );

  useEffect(() => {
    setIsLoading(sensorSelection.camera.enabled);
  }, [sensorSelection.camera.enabled]);

  const audioReady = mediaStream && mediaStream?.getAudioTracks()?.length > 0;
  const videoReady = mediaStream && mediaStream?.getVideoTracks()?.length > 0;

  // If neither the camera or microphone are enabled, the webcam preview will not render
  const webcamShown =
    sensorSelection.microphone.enabled || sensorSelection.camera.enabled;

  return (
    <div className={classnames(className, styles.webcamPreview)}>
      {webcamShown ? (
        <>
          {isRecording && (
            <span
              className={classnames(
                styles.recordingBadge,
                'text__body__semi__bold__overline__textNeutral30',
              )}
            >
              RECORDING...
            </span>
          )}
          {clipNumber && clipNumber > 1 && (
            <span
              className={classnames(
                styles.clipNumber,
                'text__body__semi__bold__overline__textNeutral00',
              )}
            >
              {`CLIP ${clipNumber}`}
            </span>
          )}
          <Webcam
            mirrored
            audio={sensorSelection.microphone.enabled}
            audioConstraints={
              sensorSelection.microphone.enabled && {
                deviceId: sensorSelection.microphone.selectedOption?.deviceId,
              }
            }
            muted
            videoConstraints={
              sensorSelection.camera.enabled && {
                ...VIDEO_DIMENSIONS,
                deviceId: sensorSelection.camera.selectedOption?.deviceId,
              }
            }
            onUserMediaError={(err: any) => {
              captureException(err, { tags: { source: 'WebcamPreview' } });
              updateSensorSelection({
                type: SensorSelectionActions.CAMERA_ERRORED,
                error: err,
              });
            }}
            onUserMedia={(stream: MediaStream) => {
              setMediaStream(stream);
            }}
          />
        </>
      ) : (
        <div className={styles.videoPlaceholder} />
      )}

      {!videoReady && isLoading && <Spinner className={styles.cameraSpinner} />}
      {!sensorSelection.camera.enabled && (
        <div className={styles.noVideoCircle}>
          <NoVideoIcon className={styles.noVideoIcon} />
        </div>
      )}
      {audioReady && (
        <AudioAnalyser
          className={styles.audioVisualizer}
          mediaStream={mediaStream}
        />
      )}
      {showButtons && (
        <div className={styles.toggleButtons}>
          <SensorToggleButton sensorType="microphone" disabled={isRecording} />
          <SensorToggleButton sensorType="camera" disabled={isRecording} />
        </div>
      )}
    </div>
  );
};

export { WebcamPreview };
