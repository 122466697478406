import { InvitationStep } from 'common/invite-to-record-process/invite-to-record-process';
import { Step } from 'react-joyride';
import { onboardingActions } from './action-types';

export enum OnboardingPhase {
  productTour = 'productTour',
  pickTutorial = 'pickTutorial',
  recordSomeoneElseStory = 'recordSomeoneElseStory',
  inviteToRecord = 'inviteToRecord',
  downloadTheApp = 'downloadTheApp',
  albumMenuTour = 'albumMenuTour',
  topMenuTour = 'topMenuTour',
}

export type InitialStateType = {
  onboardingPhase?: OnboardingPhase;
  inviteToRecordProps?: {
    handleClose: () => void;
    handleFinishProcess?: () => void;
    initialStep?: InvitationStep;
  };
  recordSomeoneElseProps?: {
    initialStep?: string;
    recordingForAClient?: boolean;
  };
  tourSteps?: Step[];
  productTourDisplayed: boolean;
  albumMenuTourDisplayed: boolean;
  topMenuTourDisplayed: boolean;
};

const initialState: InitialStateType = {
  productTourDisplayed: false,
  albumMenuTourDisplayed: false,
  topMenuTourDisplayed: false,
};

type Action = {
  type: string;
  onboardingPhase?: OnboardingPhase;
  inviteToRecordProps?: {
    handleClose: () => void;
    handleFinishProcess?: () => void;
    initialStep?: InvitationStep;
  };
  recordSomeoneElseProps?: {
    initialStep?: string;
    recordingForAClient?: boolean;
  };
  tourSteps?: Step[];
  productTourDisplayed: boolean;
  albumMenuTourDisplayed: boolean;
  topMenuTourDisplayed: boolean;
};

const OnboardingReducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case onboardingActions.SET_ONBOARDING_PHASE:
      return {
        ...state,
        onboardingPhase: action.onboardingPhase,
      };
    case onboardingActions.SET_INVITE_TO_RECORD_PROPS:
      return {
        ...state,
        inviteToRecordProps: action.inviteToRecordProps,
      };
    case onboardingActions.SET_RECORD_TO_SOMEONE_ELSE_PROPS:
      return {
        ...state,
        recordSomeoneElseProps: action.recordSomeoneElseProps,
      };
    case onboardingActions.SET_TOUR_STEPS:
      return {
        ...state,
        tourSteps: action.tourSteps,
      };
    case onboardingActions.SET_PRODUCT_TOUR_DISPLAYED:
      return {
        ...state,
        productTourDisplayed: action.productTourDisplayed,
      };
    case onboardingActions.SET_ALBUM_MENU_TOUR_DISPLAYED:
      return {
        ...state,
        albumMenuTourDisplayed: action.albumMenuTourDisplayed,
      };
    case onboardingActions.SET_TOP_MENU_TOUR_DISPLAYED:
      return {
        ...state,
        topMenuTourDisplayed: action.topMenuTourDisplayed,
      };
    default:
      return state;
  }
};

export { initialState, OnboardingReducer };
