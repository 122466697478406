import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import screenfull from 'screenfull';
import { getFileExtension } from 'helpers/file-utils';
import {
  MaximizeIcon,
  MinimizeIcon,
  PauseIconFilledIcon,
  PipExitIcon,
  PipIcon,
  PlayIcon,
  PlayIconFilledIcon,
} from 'assets/icons';
import Bowser from 'bowser';
import { classnames } from 'helpers/utils';
import styles from './mobile-video-player.module.scss';

type MobileVideoPlayerProps = {
  clipSelected: ClipType;
  notifyError: () => void;
  notifyOnPlay?: () => void;
  notifyOnEnded?: () => void;
};

const MobileVideoPlayer: React.FC<MobileVideoPlayerProps> = ({
  clipSelected,
  notifyOnEnded,
  notifyOnPlay,
  notifyError,
}) => {
  const [playing, setPlaying] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPip, setIsPip] = useState(false);

  const { browser, os } = Bowser.parse(window.navigator.userAgent);

  const isIos = os.name === 'iOS';

  const containerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>(null);

  const toggleFullScreen = async () => {
    if (screenfull.isEnabled && containerRef.current) {
      try {
        await screenfull.toggle(containerRef.current);
        setIsFullScreen((prevState) => !prevState);
      } catch (e) {
        //
      }
    }
  };

  const onFirstPlay = () => {
    setPlaying(true);
    setVideoStarted(true);
    toggleFullScreen();
    notifyOnPlay?.();
  };

  const handleDisablePip = () => {
    setIsPip(false);
  };

  const handleEnablePip = () => {
    setIsPip(true);
  };

  const togglePictureInPicture = async () => {
    setIsPip((prevState) => !prevState);
  };

  const formatTime = (time: number | undefined) => {
    if (time === undefined) {
      return '00:00';
    }
    const minutes = Math.floor(time / 60);
    const seconds = (time % 60).toFixed(0);
    return `${minutes}:${Number(seconds) < 10 ? '0' : ''}${seconds}`;
  };

  const handleProgress: ReactPlayerProps['onProgress'] = (state) => {
    setPlayed(state.playedSeconds);
  };

  const handleDuration: ReactPlayerProps['onDuration'] = (d) => {
    setDuration(Math.floor(d));
  };

  useEffect(() => {
    if (videoStarted && playerRef.current && isIos) {
      if (playerRef.current.getInternalPlayer()?.playsInline === false) {
        setTimeout(() => {
          playerRef.current!.getInternalPlayer().playsInline = true;
        }, 1000);
      }
    }
  }, [videoStarted, playerRef.current]);

  return (
    <div ref={containerRef} className={styles.container}>
      <ReactPlayer
        ref={playerRef}
        playing={playing}
        controls={videoStarted && isIos}
        className={classnames(styles.videoClipMobile)}
        url={clipSelected.videoUrl}
        width=""
        height=""
        onEnded={notifyOnEnded}
        pip={isPip}
        onEnablePIP={handleEnablePip}
        onDisablePIP={handleDisablePip}
        onPause={() => {
          setPlaying(false);
        }}
        onPlay={() => {
          setPlaying(true);
        }}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload noplaybackrate',
              poster: clipSelected.thumbnailUrl,
            },
          },
        }}
        onError={() => {
          if (
            browser.name === 'Safari' &&
            clipSelected.videoUrl &&
            getFileExtension(clipSelected.videoUrl) === '.webm'
          )
            notifyError();
        }}
        onProgress={handleProgress}
        onDuration={handleDuration}
      />
      {!videoStarted && (
        <button
          type="button"
          className={styles.playButton}
          onClick={onFirstPlay}
        >
          <PlayIcon />
        </button>
      )}
      {videoStarted && !isPip && !isIos && (
        <div className={styles.controlsContainer}>
          <div className={styles.progressBarContainer}>
            <div className="text__body__regular__tiny__textNeutral30">
              {formatTime(played)}
            </div>
            <input
              id="progress"
              type="range"
              min="0"
              max={duration}
              step="0.1"
              value={played}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                setPlayed(newValue);
                playerRef.current!.seekTo(newValue);
              }}
              className={styles.progressBar}
            />
            <div className="text__body__regular__tiny__textNeutral30">
              {formatTime(clipSelected.length)}
            </div>
          </div>
          <div className={styles.controls}>
            <button
              type="button"
              onClick={() => setPlaying((prevState) => !prevState)}
            >
              {playing ? (
                <PauseIconFilledIcon color="white" />
              ) : (
                <PlayIconFilledIcon color="white" />
              )}
            </button>
            <div className={styles.expandibleControls}>
              <button type="button" onClick={togglePictureInPicture}>
                {isPip ? <PipExitIcon /> : <PipIcon />}
              </button>
              <button type="button" onClick={toggleFullScreen}>
                {isFullScreen ? <MinimizeIcon /> : <MaximizeIcon />}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { MobileVideoPlayer };
