import React, { useContext, useEffect } from 'react';

import { Router } from 'routes';
import { routeConfig } from 'route-components';
import { Helmet } from 'react-helmet';
import { appActions, AppContext } from 'context';
import { Notification } from 'common/notification';
import { ErrorBoundary } from 'common/error-boundary';
import { FeatureFlagsProvider } from 'hooks/use-feature-flags';
import { LocalStorageApi } from 'helpers/local-storage';
import { NotificationObject } from 'models/notificationObject';
import { ApiService } from 'networking/api-service';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import 'index.scss';
import { ImageFullScreen } from 'common/image-full-screen';
import { trackUserId } from 'helpers/analytics';
import { OnboardingProcess } from 'common/onboarding-process';
import { useTriggerOnboarding } from 'hooks/use-trigger-onboarding/use-trigger-onboarding';
import { useSetToursDisplayed } from 'hooks/use-set-tours-displayed/use-set-tours-displayed';

const App = () => {
  const { dispatch, state } = useContext(AppContext);
  const { notification, metaTags, imageToEnlarge, isQrCodeImageEnlarged } =
    state;
  const { type, show, title, noMessage, message, limitTimeMs, footer, icon } =
    notification;
  const { description, image } = metaTags;

  const handleClose = () => {
    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject(null),
    });
  };
  const getAndStoreSubscriptionScheme = async () => {
    const response = await SubscriptionController.getSubscriptionTypes();
    dispatch({
      type: appActions.SUBSCRIPTIONS_SCHEME,
      subscriptionsScheme: response,
    });
  };

  useEffect(() => {
    getAndStoreSubscriptionScheme();

    const csrfToken = LocalStorageApi.get('csrfToken');
    if (csrfToken) {
      ApiService.setHeaders({ 'X-CSRF-Token': csrfToken });
    }
  }, []);

  useEffect(() => {
    if (state.data.user.id > 0) {
      trackUserId(state.data.user.id);
    } else {
      trackUserId(undefined);
    }
  }, [state.data.user.id]);

  useSetToursDisplayed();

  useTriggerOnboarding();

  return (
    <FeatureFlagsProvider>
      <ErrorBoundary>
        <Helmet>
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="436" />
          <meta property="og:image:height" content="228" />
        </Helmet>
        {imageToEnlarge && <ImageFullScreen isQrCode={isQrCodeImageEnlarged} />}
        {show && (
          <Notification
            handleClose={handleClose}
            noMessage={noMessage}
            message={message}
            title={title}
            type={type}
            limitTimeMs={limitTimeMs}
            footer={footer}
            icon={icon}
          />
        )}
        <OnboardingProcess />
        <Router routeConfig={routeConfig} />
      </ErrorBoundary>
    </FeatureFlagsProvider>
  );
};

export { App };
