import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { useContext, useEffect, useRef, useState } from 'react';
import { Step } from 'react-joyride';

export const useAlbumMenuTour = () => {
  const { dispatch: onboardingDispatch } = useContext(OnboardingContext);
  const [enableTourSteps, setEnableTourSteps] = useState(false);
  const [recalculateSteps, setRecalculateSteps] = useState(false);
  const tabsRef = useRef<HTMLDivElement>(null);
  const clipsTabRef = useRef<HTMLDivElement>(null);
  const addPromptsTabRef = useRef<HTMLDivElement>(null);
  const recordAnswersTabRef = useRef<HTMLDivElement>(null);
  const recordAnswersMenuRef = useRef<HTMLDivElement>(null);
  const uploadTabRef = useRef<HTMLDivElement>(null);
  const settingsTabRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      onboardingDispatch({
        type: onboardingActions.SET_TOUR_STEPS,
      });
      setRecalculateSteps(true);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (
      tabsRef.current &&
      clipsTabRef.current &&
      addPromptsTabRef.current &&
      recordAnswersTabRef.current &&
      recordAnswersMenuRef.current &&
      uploadTabRef.current &&
      settingsTabRef.current &&
      (recalculateSteps || enableTourSteps)
    ) {
      const albumTourSteps: Step[] = [
        {
          target: `#${tabsRef.current.id}`,
          title: 'Navigating your album',
          content:
            "The album menu allows you to access all of your album's features",
          data: {
            skippable: true,
            cleanTour: () => {
              if (recordAnswersTabRef.current?.click) {
                recordAnswersTabRef.current.click();
              }
              onboardingDispatch({
                type: onboardingActions.SET_TOUR_STEPS,
              });
              setEnableTourSteps(false);
            },
          },
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
            hideArrow: true,
            styles: { floater: { marginRight: 364 } },
          },
          spotlightPadding: 0,
        },
        {
          target: `#${clipsTabRef.current.id}`,
          title: 'Clips',
          content: 'Click here to view, edit or share album content.',
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
            hideArrow: true,
          },
          placement: 'bottom-start',
          spotlightPadding: 12,
          data: {
            mobileNextStepCallback: () => {
              if (addPromptsTabRef.current) {
                const { left, width } =
                  addPromptsTabRef.current.getBoundingClientRect();
                if (left + width > window.innerWidth) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: window.innerWidth / 2,
                    });
                  }
                }
              }
            },
          },
        },
        {
          target: `#${addPromptsTabRef.current.id}`,
          title: 'Add prompts',
          content: 'Add more prompts to your queue in this tab.',
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          placement: 'bottom-start',
          spotlightPadding: 12,
          data: {
            mobileNextStepCallback: () => {
              if (recordAnswersTabRef.current) {
                const { left, width } =
                  recordAnswersTabRef.current.getBoundingClientRect();
                if (left + width > window.innerWidth) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: window.innerWidth / 2,
                    });
                  }
                }
              }
            },
            mobileBackStepCallback: () => {
              if (clipsTabRef.current) {
                const { left } = clipsTabRef.current.getBoundingClientRect();
                if (left < 0) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: 0,
                    });
                  }
                }
              }
            },
          },
        },
        {
          target: `#${recordAnswersTabRef.current.id}`,
          title: 'Record answers',
          content: 'See all three video recording options here.',
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          placement: 'bottom-start',
          spotlightPadding: 12,
          data: {
            mobileNextStepCallback: () => {
              if (uploadTabRef.current) {
                const { left, width } =
                  uploadTabRef.current.getBoundingClientRect();
                if (left + width > window.innerWidth) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: window.innerWidth / 2,
                    });
                  }
                }
              }
            },
            mobileBackStepCallback: () => {
              if (addPromptsTabRef.current) {
                const { left } =
                  addPromptsTabRef.current.getBoundingClientRect();
                if (left < 0) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: 0,
                    });
                  }
                }
              }
            },
          },
        },
        {
          target: `#${recordAnswersMenuRef.current?.id}`,
          content: (
            <p>
              <span className="text__body__semi__bold__medium__textNeutral40">
                Record myself:
              </span>{' '}
              Record using your webcam. <br />
              <br />
              <span className="text__body__semi__bold__medium__textNeutral40">
                Record on video call:
              </span>{' '}
              Join a video call to record with someone else—either interview
              them or have them interview you. <br />
              <br />{' '}
              <span className="text__body__semi__bold__medium__textNeutral40">
                Invite someone to record:
              </span>{' '}
              Give friends, family, or someone else access to add content to
              your album.
            </p>
          ),
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          placement: 'right-start',
          spotlightPadding: 0,
          data: {
            positionOnMobile: 'right',
          },
        },
        {
          target: `#${uploadTabRef.current.id}`,
          title: 'Upload content',
          content: (
            <p>
              In this tab you can upload previously recorded videos. <br />
              <br /> You can also upload or scan documents and images from the
              Generational Story app.
            </p>
          ),
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          placement: 'bottom-start',
          spotlightPadding: 12,
          data: {
            mobileNextStepCallback: () => {
              if (settingsTabRef.current) {
                const { left, width } =
                  settingsTabRef.current.getBoundingClientRect();
                if (left + width > window.innerWidth) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: window.innerWidth / 2,
                    });
                  }
                }
              }
            },
            mobileBackStepCallback: () => {
              if (recordAnswersTabRef.current) {
                const { left } =
                  recordAnswersTabRef.current.getBoundingClientRect();
                if (left < 0) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: 0,
                    });
                  }
                }
              }
            },
          },
        },
        {
          target: `#${settingsTabRef.current.id}`,
          title: 'Settings',
          content:
            'Review your subscription and usage, set email reminders to record for yourself or for your contributors, and manage other album settings.',
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          data: {
            cleanTour: () => {
              if (recordAnswersTabRef.current?.click) {
                recordAnswersTabRef.current.click();
              }
              onboardingDispatch({
                type: onboardingActions.SET_TOUR_STEPS,
              });
              setEnableTourSteps(false);
            },
            mobileNextStepCallback: () => {
              if (settingsTabRef.current) {
                const { left, width } =
                  settingsTabRef.current.getBoundingClientRect();
                if (left + width > window.innerWidth) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: window.innerWidth / 2,
                    });
                  }
                }
              }
            },
            mobileBackStepCallback: () => {
              if (uploadTabRef.current) {
                const { left } = uploadTabRef.current.getBoundingClientRect();
                if (left < 0) {
                  if (tabsRef.current) {
                    tabsRef.current.children[0].scrollTo({
                      left: 0,
                    });
                  }
                }
              }
            },
          },
          placement: 'bottom-start',
          spotlightPadding: 12,
        },
      ];

      if (tabsRef.current) {
        tabsRef.current.children[0].scrollTo({
          left: 0,
        });
      }
      onboardingDispatch({
        type: onboardingActions.SET_TOUR_STEPS,
        tourSteps: albumTourSteps,
      });
      setRecalculateSteps(false);
    }

    return () =>
      onboardingDispatch({
        type: onboardingActions.SET_TOUR_STEPS,
      });
  }, [
    tabsRef.current,
    clipsTabRef,
    addPromptsTabRef,
    recordAnswersTabRef,
    recordAnswersMenuRef,
    uploadTabRef,
    settingsTabRef,
    enableTourSteps,
    recalculateSteps,
  ]);

  return {
    tabsRef,
    clipsTabRef,
    addPromptsTabRef,
    recordAnswersTabRef,
    recordAnswersMenuRef,
    uploadTabRef,
    settingsTabRef,
    setEnableTourSteps,
  };
};
