/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  MenuIcon,
  SettingsIcon,
  CrossIcon,
  AlbumsIcon,
  FileTextIcon,
  ShoppingCartIcon,
  UsersIcon,
  LogoutIcon,
} from 'assets/icons';
import { classnames, isAnyLoggedUser } from 'helpers/utils';
import { RouteName, goToPage } from 'routes';
import { Menu } from 'common/menu';
import { useClickOutside } from 'hooks/click-outside';
import { Breakpoints, NavSections } from 'common/enums';
import CypressIds from 'cypressIds';
import { useMediaQuery } from 'hooks/use-media-query';
import styles from './right-section.module.scss';

type OptionNavbarMenuProps = {
  tab: NavSections;
  handleTabSelected: (queryParams: ParamsType) => void;
};

const sectionsIcons: Record<NavSections, ReactNode> = {
  [NavSections.albums]: <AlbumsIcon />,
  [NavSections.contributors]: <UsersIcon />,
  [NavSections.templates]: <FileTextIcon />,
  [NavSections.store]: <ShoppingCartIcon />,
  [NavSections.account]: <SettingsIcon />,
  [NavSections.logout]: <LogoutIcon />,
};

const OptionNavbarMenu: React.FC<OptionNavbarMenuProps> = ({
  tab,
  handleTabSelected,
}) => (
  <button
    type="button"
    onClick={() => handleTabSelected({ tab })}
    className={classnames(
      'text__body__regular__medium__textNeutral30',
      styles.optionMenu,
    )}
    data-cy={tab}
  >
    {sectionsIcons[tab]}
    {tab}
  </button>
);

type RightSectionProps = {
  user: UserType;
  logOutUser: () => void;
  tourMode?: boolean;
  notifyMenuOpen?: () => void;
  menuRef?: RefObject<HTMLDivElement>;
  menuButtonRefProp?: RefObject<HTMLDivElement>;
};

const RightSection: React.FC<RightSectionProps> = ({
  user,
  logOutUser,
  menuRef,
  tourMode,
  menuButtonRefProp,
  notifyMenuOpen,
}) => {
  const [showUserOptionsMenu, setShowUserOptionsMenu] = useState(false);
  const containerUserOptionsRef = useRef(null);
  const menuButtonRef = menuButtonRefProp ?? useRef<HTMLDivElement>(null);
  const isMobileOrTablet = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);

  useClickOutside(
    containerUserOptionsRef,
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (
        menuButtonRef.current &&
        !menuButtonRef.current.contains(e.target as HTMLElement) &&
        !tourMode
      ) {
        setShowUserOptionsMenu(false);
      }
    },
  );

  useEffect(() => {
    if (tourMode && !showUserOptionsMenu) {
      setShowUserOptionsMenu(true);
      notifyMenuOpen?.();
    }
  }, [tourMode]);

  const showLoginButton = () => (
    <button
      type="button"
      onClick={() => {
        goToPage(RouteName.SignIn);
      }}
    >
      <span className="text__body__semi__bold__medium__primary50"> Login </span>
    </button>
  );

  const showMenu = () => (
    <div ref={containerUserOptionsRef}>
      <Menu menuRef={menuRef} width={styles.optionsMenuWidth}>
        {isMobileOrTablet && (
          <>
            <span>
              Hi,&nbsp; <span className={styles.name}>{user.name}</span>!
            </span>
            <div className={classnames(styles.divider)} />
          </>
        )}
        <OptionNavbarMenu
          tab={NavSections.account}
          handleTabSelected={() => goToPage(RouteName.Account)}
        />
        <OptionNavbarMenu
          tab={NavSections.albums}
          handleTabSelected={() => goToPage(RouteName.Albums)}
        />
        <OptionNavbarMenu
          tab={NavSections.contributors}
          handleTabSelected={() => goToPage(RouteName.Contributors)}
        />
        <OptionNavbarMenu
          tab={NavSections.templates}
          handleTabSelected={() => goToPage(RouteName.Templates)}
        />
        <OptionNavbarMenu
          tab={NavSections.store}
          handleTabSelected={() => goToPage(RouteName.Store)}
        />
        <div className={classnames(styles.divider)} />
        <OptionNavbarMenu
          tab={NavSections.logout}
          handleTabSelected={() => logOutUser()}
        />
      </Menu>
    </div>
  );

  const showUserMenu = () => (
    <div className={styles.menuContainer}>
      {!isMobileOrTablet && (
        <span>
          Hi,&nbsp; <span className={styles.name}>{user.name}</span>!
        </span>
      )}
      <div
        role="button"
        ref={menuButtonRef}
        tabIndex={0}
        onClick={() => setShowUserOptionsMenu((prevState) => !prevState)}
        className={classnames(styles.menuButton, {
          [styles.selected]: showUserOptionsMenu,
        })}
        data-cy={CypressIds.navbarMenu}
      >
        <div className={styles.icon}>
          <CrossIcon
            className={classnames({ [styles.hidden]: !showUserOptionsMenu })}
          />
          <MenuIcon
            className={classnames({ [styles.hidden]: showUserOptionsMenu })}
          />
        </div>
        <div className={styles.menu}>{showUserOptionsMenu && showMenu()}</div>
      </div>
    </div>
  );

  return isAnyLoggedUser(user) ? showUserMenu() : showLoginButton();
};

export { RightSection };
