import { Breakpoints } from 'common/enums';
import { OnboardingMessage } from 'common/onboarding-message';
import { useMediaQuery } from 'hooks/use-media-query';
import React, { useContext, useEffect, useState } from 'react';
import Joyride, { CallBackProps, EVENTS, Step } from 'react-joyride';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import { LocalStorageApi } from 'helpers/local-storage';
import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { Tooltip } from './components/tooltip';

type SpotlightTourProps = {
  steps: Step[];
  handleFinishOnboarding: () => void;
};

const SpotlightTour: React.FC<SpotlightTourProps> = ({
  steps,
  handleFinishOnboarding,
}) => {
  const {
    state: { onboardingPhase },
    dispatch,
  } = useContext(OnboardingContext);
  const [currentStep, setCurrentStep] = useState(0);
  const isMobileOrTablet = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);
  const isLastStep = currentStep === steps.length - 1;

  const handleEndTutorial = (step: Step) => {
    if (onboardingPhase === OnboardingPhase.topMenuTour) {
      handleFinishOnboarding();
      dispatch({
        type: onboardingActions.SET_TOP_MENU_TOUR_DISPLAYED,
        topMenuTourDisplayed: true,
      });
      LocalStorageApi.set('top_menu_tour_displayed', true);
    } else {
      dispatch({
        type: onboardingActions.SET_ONBOARDING_PHASE,
        onboardingPhase: OnboardingPhase.topMenuTour,
      });
      LocalStorageApi.set('onboarding_phase', OnboardingPhase.topMenuTour);
      dispatch({
        type: onboardingActions.SET_ALBUM_MENU_TOUR_DISPLAYED,
        albumMenuTourDisplayed: true,
      });
      LocalStorageApi.set('album_menu_tour_displayed', true);
    }
    if (step.data?.cleanTour) {
      step.data.cleanTour();
    }
  };

  const handleStepCallback = (data: CallBackProps) => {
    const { type, step } = data;
    if (type === EVENTS.TOUR_END) {
      handleEndTutorial(step);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (isMobileOrTablet) {
    return (
      <OnboardingMessage
        target={steps[currentStep].target}
        title={steps[currentStep].title}
        content={steps[currentStep].content}
        skippable={steps[currentStep].data?.skippable}
        nextButton={{
          text: isLastStep ? 'Got it' : 'Next',
          action: () => {
            if (isLastStep) {
              handleEndTutorial(steps[currentStep]);
            } else {
              setCurrentStep((prev) => prev + 1);
            }
          },
        }}
        backButton={
          currentStep > 0
            ? {
                text: 'Back',
                action: () => {
                  setCurrentStep((prev) => prev - 1);
                },
              }
            : undefined
        }
        handleSkipTutorial={() => {
          handleEndTutorial(steps[currentStep]);
        }}
        spotlightPadding={steps[currentStep].spotlightPadding}
        nextCallback={steps[currentStep].data?.mobileNextStepCallback}
        backCallback={steps[currentStep].data?.mobileBackStepCallback}
        position={steps[currentStep].data?.positionOnMobile}
      />
    );
  }

  return (
    <Joyride
      tooltipComponent={Tooltip}
      steps={steps}
      callback={handleStepCallback}
      continuous
      disableScrolling
      disableCloseOnEsc
      disableOverlayClose
    />
  );
};

export { SpotlightTour };
