import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { QuestionsSelector } from 'common/questions-selector';
import { CameraUpload } from 'common/camera-upload';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import {
  isFreeTrial,
  isTemporaryUser,
  userPermissionUpgrade,
} from 'helpers/utils';
import { AppContext } from 'context';
import { Breakpoints } from 'common/enums';
import { SelfRecordingContext } from 'context/self-recording-context';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { useMediaQuery } from 'hooks/use-media-query';
import { LinkIcon } from 'assets/icons';
import { AlbumLimitedConditionWarning } from 'common/album-limited-condition-warning';
import { AlbumLimitedConditionWarningType } from 'common/album-limited-condition-warning/album-limited-condition-warning';
import { ModalWarning } from 'common/modal-warning';
import { goToPage, RouteName } from 'routes';
import { InviteToRecordProcess } from 'common/invite-to-record-process';
import styles from './record-upload.module.scss';

type RecordUploadProps = {
  album: AlbumType;
  setShowLearnMoreSubscriptionModal: Dispatch<React.SetStateAction<boolean>>;
  upgradeAction: () => void;
  refetchAlbum: () => Promise<void>;
};

const RecordUpload: React.FC<RecordUploadProps> = ({
  album,
  setShowLearnMoreSubscriptionModal,
  upgradeAction,
  refetchAlbum,
}) => {
  const {
    state: {
      data: { user },
    },
  } = useContext(AppContext);
  const { state, dispatch } = useContext(SelfRecordingContext);
  const [isCustomRecording, setIsCustomRecording] = useState<boolean>(false);
  const [showModalSignUp, setShowModalSignUp] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const maxQuestionAllowed = album.maxClips;
  const onSelectCustomRecording = () => {
    dispatch({
      type: selfRecordingActions.setSelectedQuestion,
      selectedQuestion: undefined,
    });
    setIsCustomRecording(true);
  };
  const showWarningLimitCondition =
    isFreeTrial(album) && maxQuestionAllowed === album.clipAmount;

  const showWarningAlbumLimitedCondition = () => (
    <AlbumLimitedConditionWarning
      title="Your trial album is full"
      type={AlbumLimitedConditionWarningType.Alert}
      content={
        <div className={styles.warningAlbumLimit}>
          <Button
            buttonStyle={ButtonStyle.SecondaryGhostLink}
            buttonSize={ButtonSize.Medium}
            onClick={() => setShowLearnMoreSubscriptionModal(true)}
          >
            Upgrade to a subscription
          </Button>
          <span className="text__body__regular__medium__textNeutral40">
            {' '}
            to increase your limit.
          </span>
        </div>
      }
    />
  );

  const selectTheNextQuestion = useCallback(() => {
    const questionSelectedUna = album.unansweredQuestions?.find(
      (item) => item.id === state.selectedQuestion?.id,
    );
    if (!questionSelectedUna) {
      const question = album.unansweredQuestions
        ? album.unansweredQuestions[0]
        : undefined;
      if (state.mode === 'upload' || !question) {
        onSelectCustomRecording();
      } else {
        setIsCustomRecording(false);
        dispatch({
          type: selfRecordingActions.setSelectedQuestion,
          selectedQuestion: question,
        });
      }
    }
  }, [state.mode, state.selectedQuestion]);

  useEffect(() => {
    if (isTemporaryUser(user) && album.maxClips === album.clipAmount) {
      setShowModalSignUp(true);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: selfRecordingActions.setAlbumId, albumId: album.id });
    selectTheNextQuestion();
  }, [state.mode]);

  const onSelectQuestion = (id?: string) => {
    setIsCustomRecording(false);
    const question = album.unansweredQuestions?.find((item) => item.id === id);
    if (state.videosRecorded.length > 0) {
      dispatch({
        type: selfRecordingActions.deleteAllVideos,
      });
    }
    dispatch({
      type: selfRecordingActions.setSelectedQuestion,
      selectedQuestion: question,
    });
  };

  const [showInviteGuestModal, setShowInviteGuestModal] = useState(false);

  const showInviteGuestModalComponent = () => (
    <InviteToRecordProcess handleClose={() => setShowInviteGuestModal(false)} />
  );

  const showMobileLayout = () => (
    <div className={styles.cameraSection}>
      <CameraUpload
        album={album}
        selectedQuestion={state.selectedQuestion}
        notifySelectQuestion={onSelectQuestion}
        notifySelectCustomRecording={onSelectCustomRecording}
        notifyShowInviteModal={() => setShowInviteGuestModal(true)}
        refetchAlbum={refetchAlbum}
        upgradeAction={upgradeAction}
      />
    </div>
  );

  const showSignUpModal = () => (
    <ModalWarning
      title="Sign up to save your recordings"
      content={
        <div className={styles.modalSignUpInfo}>
          <div className="text__body__regular__medium__textNeutral40">
            You’ve run out of free trial clips! Please complete the sign up
            process to be able to save your clips and access them later,
            download or share them, as well as access the rest of our features.
          </div>
          <div className="text__body__regular__medium__textNeutral40">
            Don’t worry, your first album is{' '}
            <span className="text__body__semi__bold__medium__textNeutral40">
              free
            </span>{' '}
            for a month, and signing up only takes 2 minutes!
          </div>
        </div>
      }
      successButtonText="Sign up"
      closeFn={() => setShowModalSignUp(false)}
      successFn={() => goToPage(RouteName.SignUp)}
      successStyleButton={ButtonStyle.PrimaryFilled}
    />
  );

  useEffect(() => {
    selectTheNextQuestion();
  }, [album.unansweredQuestions?.length]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        isMobile &&
        document.visibilityState === 'hidden' &&
        state.isRecording
      ) {
        dispatch({
          type: selfRecordingActions.cleanState,
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isMobile, state.isRecording]);

  return (
    <div className={styles.allPage}>
      {showInviteGuestModal && showInviteGuestModalComponent()}
      {showModalSignUp && showSignUpModal()}
      {isMobile ? (
        showMobileLayout()
      ) : (
        <>
          <div className={styles.questionsSection}>
            <div className={styles.questionsHeader}>
              <p className={styles.questionsTitle}>Select a prompt</p>
              <div className={styles.inviteGuest}>
                <p className="text__body__regular__overline__textNeutral30">
                  OR
                </p>
                <Button
                  className={styles.inviteButton}
                  buttonSize={ButtonSize.Small}
                  buttonType={ButtonType.Button}
                  buttonStyle={ButtonStyle.PrimaryStroke}
                  onClick={() => setShowInviteGuestModal(true)}
                  disabled={
                    state.isRecording ||
                    showWarningLimitCondition ||
                    !user.permissions?.permissions.inviteGuest
                  }
                  tooltipContent="Available after sign up"
                  tooltip={!user.permissions?.permissions.inviteGuest}
                  tooltipPosition="top"
                  tooltipId="invite-disabled-tooltip"
                >
                  <div className={styles.inviteButtonContent}>
                    <LinkIcon />
                    Invite collaborator(s)
                  </div>
                </Button>
              </div>
            </div>
            <QuestionsSelector
              album={album}
              selectedQuestion={state.selectedQuestion}
              onSelectQuestion={onSelectQuestion}
              isCustomRecording={isCustomRecording}
              onSelectCustomRecording={onSelectCustomRecording}
            />
          </div>
          <div className={styles.cameraSection}>
            {showWarningLimitCondition &&
              userPermissionUpgrade(user) &&
              showWarningAlbumLimitedCondition()}
            <CameraUpload
              album={album}
              selectedQuestion={state.selectedQuestion}
              notifySelectQuestion={onSelectQuestion}
              notifySelectCustomRecording={onSelectCustomRecording}
              notifyShowInviteModal={() => setShowInviteGuestModal(true)}
              upgradeAction={upgradeAction}
              refetchAlbum={refetchAlbum}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { RecordUpload };
