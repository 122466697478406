import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { useContext, useEffect, useRef, useState } from 'react';
import { Step } from 'react-joyride';

export const useTopMenuTour = () => {
  const { dispatch: onboardingDispatch } = useContext(OnboardingContext);
  const [enableTourSteps, setEnableTourSteps] = useState(false);
  const [recalculateSteps, setRecalculateSteps] = useState(false);
  const navBarRef = useRef<HTMLDivElement>(null);
  const activeAlbumRef = useRef<HTMLDivElement>(null);
  const copyLinkRef = useRef<HTMLDivElement>(null);
  const hamburgerMenuRef = useRef<HTMLDivElement>(null);
  const userMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      onboardingDispatch({
        type: onboardingActions.SET_TOUR_STEPS,
      });
      setRecalculateSteps(true);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (
      navBarRef.current &&
      activeAlbumRef.current &&
      copyLinkRef.current &&
      hamburgerMenuRef.current &&
      userMenuRef.current &&
      (recalculateSteps || enableTourSteps)
    ) {
      const topTourSteps: Step[] = [
        {
          target: `#${navBarRef.current.id}`,
          title: 'Navigating Generational Story',
          content:
            'The top menu allows you to navigate to other albums you created or have access to, share your album and more.',
          data: {
            skippable: true,
            cleanTour: () => {
              if (hamburgerMenuRef.current?.click) {
                hamburgerMenuRef.current.click();
              }
              onboardingDispatch({
                type: onboardingActions.SET_TOUR_STEPS,
              });
              setEnableTourSteps(false);
            },
          },
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          placement: 'bottom-start',
          spotlightPadding: 0,
        },
        {
          target: `#${activeAlbumRef.current.id}`,
          title: 'Active album',
          content: (
            <p>
              Click on your album to change the name and cover image. <br />
              <br /> Click on change album to open a different album.
            </p>
          ),
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          placement: 'bottom-start',
          styles: { spotlight: { borderRadius: 32 } },
          spotlightPadding: 0,
        },
        {
          target: `#${copyLinkRef.current.id}`,
          title: 'Copy link to album',
          content: (
            <p>
              To allow someone else to view your album, click &apos;copy
              link&apos; and send it to a friend or post it on social media.{' '}
              <br />
              <br /> Any clip designated as &apos;private&apos; will not appear
              when opened through the sharing link.
            </p>
          ),
          disableBeacon: true,
          floaterProps: { disableAnimation: true, hideArrow: true },
          placement: 'bottom-start',
          spotlightPadding: 0,
        },
        {
          target: `#${userMenuRef.current.id}`,
          title: 'Menu',
          content:
            'Explore the menu options to: view and edit your account, see all the contributors you added to your albums, create your own prompt templates, browse the GS store, turn on or off the product tour, and logout.',
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
            hideArrow: true,
            styles: { floater: { marginRight: 364 } },
          },
          data: {
            cleanTour: () => {
              if (hamburgerMenuRef.current?.click) {
                hamburgerMenuRef.current.click();
              }
              onboardingDispatch({
                type: onboardingActions.SET_TOUR_STEPS,
              });
              setEnableTourSteps(false);
            },
          },
          placement: 'left-start',
        },
      ];

      onboardingDispatch({
        type: onboardingActions.SET_TOUR_STEPS,
        tourSteps: topTourSteps,
      });
      setRecalculateSteps(false);
    }

    return () =>
      onboardingDispatch({
        type: onboardingActions.SET_TOUR_STEPS,
      });
  }, [
    navBarRef,
    activeAlbumRef,
    copyLinkRef,
    hamburgerMenuRef,
    userMenuRef,
    enableTourSteps,
    recalculateSteps,
  ]);

  return {
    navBarRef,
    activeAlbumRef,
    copyLinkRef,
    hamburgerMenuRef,
    userMenuRef,
    setEnableTourSteps,
  };
};
