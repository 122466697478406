import { Modal } from 'common/modal';
import React, { useContext, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import { InvitationStep } from 'common/invite-to-record-process/invite-to-record-process';
import { LocalStorageApi } from 'helpers/local-storage';
import styles from './record-someone-else-story.module.scss';

enum RecordSomeoneElseStepsEnum {
  choosePerson = 'choosePerson',
  makePersonContributor = 'addPrompts',
  helpPlanning = 'helpPlanning',
  choosePromptsOrTemplate = 'choosePromptsOrTemplate',
  chooseRecorder = 'chooseRecorder',
  chooseRecordingMethod = 'chooseRecordingMethod',
}

type RecordSomeoneElseStepContent = {
  title: string;
  description?: string;
  leftButton: {
    text: string;
    action: () => void;
  };
  rightButton: {
    text: string;
    action: () => void;
  };
};

type RecordSomeoneElseSteps = {
  [key in RecordSomeoneElseStepsEnum]: RecordSomeoneElseStepContent;
};

const RecordSomeoneElseStory: React.FC = () => {
  const {
    dispatch,
    state: { recordSomeoneElseProps },
  } = useContext(OnboardingContext);

  const [step, setStep] = useState(
    recordSomeoneElseProps?.initialStep ??
      RecordSomeoneElseStepsEnum.choosePerson,
  );

  const steps: RecordSomeoneElseSteps = {
    [RecordSomeoneElseStepsEnum.choosePerson]: {
      title: 'Who are you going to record?',
      leftButton: {
        text: 'A friend or loved one',
        action: () => {
          dispatch({
            type: onboardingActions.SET_RECORD_TO_SOMEONE_ELSE_PROPS,
            recordSomeoneElseProps: {
              ...recordSomeoneElseProps,
              recordingForAClient: false,
            },
          });
          setStep(RecordSomeoneElseStepsEnum.makePersonContributor);
        },
      },
      rightButton: {
        text: 'A client',
        action: () => {
          dispatch({
            type: onboardingActions.SET_RECORD_TO_SOMEONE_ELSE_PROPS,
            recordSomeoneElseProps: {
              ...recordSomeoneElseProps,
              recordingForAClient: true,
            },
          });
          setStep(RecordSomeoneElseStepsEnum.makePersonContributor);
        },
      },
    },
    [RecordSomeoneElseStepsEnum.makePersonContributor]: {
      title: 'Do you want this person to contribute to their own album?',
      description:
        'Contributors have permanent management access to the album. They can add prompts, see, add, edit and delete content.',
      leftButton: {
        text: 'Yes',
        action: () => {
          dispatch({
            type: onboardingActions.SET_INVITE_TO_RECORD_PROPS,
            inviteToRecordProps: {
              initialStep: InvitationStep.inviteContributors,
              handleClose: () => {
                dispatch({
                  type: onboardingActions.SET_RECORD_TO_SOMEONE_ELSE_PROPS,
                  recordSomeoneElseProps: {
                    ...recordSomeoneElseProps,
                    initialStep: RecordSomeoneElseStepsEnum.helpPlanning,
                  },
                });
                dispatch({
                  type: onboardingActions.SET_ONBOARDING_PHASE,
                  onboardingPhase: OnboardingPhase.recordSomeoneElseStory,
                });
                LocalStorageApi.set(
                  'onboarding_phase',
                  OnboardingPhase.recordSomeoneElseStory,
                );
              },
            },
          });
          dispatch({
            type: onboardingActions.SET_ONBOARDING_PHASE,
            onboardingPhase: OnboardingPhase.inviteToRecord,
          });
        },
      },
      rightButton: {
        text: 'No',
        action: () => {
          setStep(RecordSomeoneElseStepsEnum.helpPlanning);
        },
      },
    },
    [RecordSomeoneElseStepsEnum.helpPlanning]: {
      title: 'Do you want to help plan out their album?',
      description:
        "You can be as involved as you want! Select 'yes' to add prompts for the respondent to answer, or 'no' if you'd prefer to do this later or let them choose their own prompts.",
      leftButton: {
        text: 'Yes',
        action: () => {
          if (!recordSomeoneElseProps?.recordingForAClient) {
            // TODO: Redirect to "add prompts" flow and return back
          } else {
            setStep(RecordSomeoneElseStepsEnum.choosePromptsOrTemplate);
          }
        },
      },
      rightButton: {
        text: 'No',
        action: () => {
          setStep(RecordSomeoneElseStepsEnum.chooseRecorder);
        },
      },
    },
    [RecordSomeoneElseStepsEnum.choosePromptsOrTemplate]: {
      title:
        'Do you want to choose your own prompts or use a professional template for a 30-minute interview?',
      description:
        'You have the option pick a ready-to-go template, or customize your own prompts by browsing our library or typing your own  prompts.',
      leftButton: {
        text: 'Choose your own',
        action: () => {
          // TODO: Redirect to "add prompts" flow and return back
          setStep(RecordSomeoneElseStepsEnum.chooseRecorder); // This should be removed after redirecting to the add prompts flow
        },
      },
      rightButton: {
        text: 'Premade template',
        action: () => {
          // TODO: Redirect to "professional template" flow and return back
          setStep(RecordSomeoneElseStepsEnum.chooseRecorder); // This should be removed after redirecting to the template flow
        },
      },
    },
    [RecordSomeoneElseStepsEnum.chooseRecorder]: {
      title: 'Who will be recording the story?',
      description:
        'Will the respondent record on their own, or will you record in person or via video conference?',
      leftButton: {
        text: 'I will record',
        action: () => {
          setStep(RecordSomeoneElseStepsEnum.chooseRecordingMethod);
        },
      },
      rightButton: {
        text: 'They will record',
        action: () => {
          dispatch({
            type: onboardingActions.SET_INVITE_TO_RECORD_PROPS,
            inviteToRecordProps: {
              handleClose: () => {
                dispatch({
                  type: onboardingActions.SET_RECORD_TO_SOMEONE_ELSE_PROPS,
                  recordSomeoneElseProps: {
                    ...recordSomeoneElseProps,
                    initialStep:
                      RecordSomeoneElseStepsEnum.chooseRecordingMethod,
                  },
                });
                dispatch({
                  type: onboardingActions.SET_ONBOARDING_PHASE,
                  onboardingPhase: OnboardingPhase.recordSomeoneElseStory,
                });
                LocalStorageApi.set(
                  'onboarding_phase',
                  OnboardingPhase.recordSomeoneElseStory,
                );
              },
            },
          });
          dispatch({
            type: onboardingActions.SET_ONBOARDING_PHASE,
            onboardingPhase: OnboardingPhase.inviteToRecord,
          });
          LocalStorageApi.set(
            'onboarding_phase',
            OnboardingPhase.inviteToRecord,
          );
        },
      },
    },
    [RecordSomeoneElseStepsEnum.chooseRecordingMethod]: {
      title: 'How will the interview be conducted?',
      leftButton: {
        text: 'In person',
        action: () => {
          dispatch({
            type: onboardingActions.SET_ONBOARDING_PHASE,
            onboardingPhase: OnboardingPhase.downloadTheApp,
          });
          LocalStorageApi.set(
            'onboarding_phase',
            OnboardingPhase.downloadTheApp,
          );
        },
      },
      rightButton: {
        text: 'Through video call',
        action: () => {
          // TODO: Redirect to video-call tutorial
          dispatch({
            type: onboardingActions.SET_ONBOARDING_PHASE,
            onboardingPhase: OnboardingPhase.inviteToRecord,
          });
          LocalStorageApi.set(
            'onboarding_phase',
            OnboardingPhase.inviteToRecord,
          );
        },
      },
    },
  };

  return (
    <Modal contentClassName={styles.container} disableManualClosing>
      <div className={styles.content}>
        <div className="text__heading3__primary50">
          {steps[step as RecordSomeoneElseStepsEnum].title}
        </div>
        {steps[step as RecordSomeoneElseStepsEnum].title && (
          <div className="text__body__regular__medium__textNeutral30">
            {steps[step as RecordSomeoneElseStepsEnum].description}
          </div>
        )}
        <div className={styles.buttons}>
          <Button
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={
              steps[step as RecordSomeoneElseStepsEnum].leftButton.action
            }
          >
            {steps[step as RecordSomeoneElseStepsEnum].leftButton.text}
          </Button>
          <Button
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={
              steps[step as RecordSomeoneElseStepsEnum].rightButton.action
            }
          >
            {steps[step as RecordSomeoneElseStepsEnum].rightButton.text}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { RecordSomeoneElseStory };
