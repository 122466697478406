import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import React, { useContext } from 'react';
import { InviteToRecordProcess } from 'common/invite-to-record-process';
import { appActions, AppContext } from 'context';
import { UserController } from 'networking/controllers/user-controller';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { findRoute, RouteName } from 'routes';
import { LocalStorageApi } from 'helpers/local-storage';
import { DownloadTheAppModal } from './components/download-the-app-modal';
import { RecordSomeoneElseStory } from './components/record-someone-else-story';
import { PickTutorials } from './components/pick-tutorials/pick-tutorials';
import { ProductTourCarousel } from './components/product-tour-carousel/product-tour-carousel';
import 'assets/stylesheets/colors.scss';
import { SpotlightTour } from './components/spotlight-tour';

type OnboardingPhases = {
  [key in OnboardingPhase]: React.ReactElement;
};

type OnboardingProcessType = {};

const OnboardingProcess: React.FC<OnboardingProcessType> = () => {
  const { state: generalState, dispatch: generalDispatch } =
    useContext(AppContext);
  const {
    state: {
      onboardingPhase,
      inviteToRecordProps,
      tourSteps,
      productTourDisplayed,
      albumMenuTourDisplayed,
      topMenuTourDisplayed,
    },
    dispatch,
  } = useContext(OnboardingContext);

  const handleFinishOnboarding = async () => {
    try {
      const userResponse = await UserController.update({
        ...generalState.data.user,
        firstVisitDisplayed: true,
      });
      generalDispatch({
        type: appActions.USER_LOGGED,
        data: { user: userResponse },
      });
      dispatch({ type: onboardingActions.SET_ONBOARDING_PHASE });
      LocalStorageApi.set('onboarding_phase', null);
    } catch (e) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Skip tutorial',
          message: "Tutorial couldn't be skipped",
          type: NotificationType.Error,
        }),
      });
    }
  };

  const onboardingPhases: OnboardingPhases = {
    [OnboardingPhase.productTour]: !productTourDisplayed ? (
      <ProductTourCarousel
        handleClose={() => {
          dispatch({
            type: onboardingActions.SET_ONBOARDING_PHASE,
            onboardingPhase: OnboardingPhase.albumMenuTour,
          });
          LocalStorageApi.set(
            'onboarding_phase',
            OnboardingPhase.albumMenuTour,
          );
          dispatch({
            type: onboardingActions.SET_PRODUCT_TOUR_DISPLAYED,
            productTourDisplayed: true,
          });
          LocalStorageApi.set('product_tour_displayed', true);
        }}
      />
    ) : (
      <div />
    ),
    [OnboardingPhase.pickTutorial]: (
      <PickTutorials handleSkipTutorial={handleFinishOnboarding} />
    ),
    [OnboardingPhase.albumMenuTour]:
      !albumMenuTourDisplayed && tourSteps ? (
        <SpotlightTour
          steps={tourSteps}
          handleFinishOnboarding={handleFinishOnboarding}
        />
      ) : (
        <div />
      ),
    [OnboardingPhase.topMenuTour]:
      !topMenuTourDisplayed && tourSteps ? (
        <SpotlightTour
          steps={tourSteps}
          handleFinishOnboarding={handleFinishOnboarding}
        />
      ) : (
        <div />
      ),
    [OnboardingPhase.recordSomeoneElseStory]: <RecordSomeoneElseStory />,
    [OnboardingPhase.inviteToRecord]: (
      <InviteToRecordProcess
        handleClose={
          inviteToRecordProps?.handleClose ??
          (() => {
            dispatch({ type: onboardingActions.SET_ONBOARDING_PHASE });
            LocalStorageApi.set('onboarding_phase', null);
          })
        }
        handleFinishProcess={inviteToRecordProps?.handleFinishProcess}
        initialStep={inviteToRecordProps?.initialStep}
      />
    ),
    [OnboardingPhase.downloadTheApp]: <DownloadTheAppModal />,
  };

  const currentPath = window.location.pathname
    .split('/')
    .find((value) => value.length);
  const verifyEmailPath = findRoute(RouteName.EmailVerifying)
    .path.split('/')
    .find((value) => value.length);
  const isVerifyEmailPage = currentPath && currentPath === verifyEmailPath;
  const showOnboarding =
    !generalState.data.user.firstVisitDisplayed && !isVerifyEmailPage;

  if (onboardingPhase && showOnboarding)
    return onboardingPhases[onboardingPhase];

  return null;
};

export { OnboardingProcess };
