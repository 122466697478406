import React from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { TooltipRenderProps } from 'react-joyride';
import styles from './tooltip.module.scss';

export enum PositionType {
  right = 'right',
  left = 'left',
  bottom = 'bottom',
  top = 'top',
}

const Tooltip: React.FC<TooltipRenderProps> = (props) => {
  const { backProps, primaryProps, skipProps, step, isLastStep, index } = props;

  return (
    <div className={styles.container}>
      {step.title && (
        <div className="text__body__semi__bold__medium__textNeutral40">
          {step.title}
        </div>
      )}
      <div className="text__body__regular__medium__textNeutral30">
        {step.content}
      </div>
      <div className={styles.footer}>
        <div>
          {step.data?.skippable && (
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.GreyGhost}
              onClick={skipProps.onClick}
            >
              {skipProps.title}
            </Button>
          )}
        </div>
        <div className={styles.buttons}>
          {index > 0 && (
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.PrimaryStroke}
              onClick={backProps.onClick}
            >
              {backProps.title}
            </Button>
          )}
          <Button buttonSize={ButtonSize.Small} onClick={primaryProps.onClick}>
            {isLastStep ? 'Got it' : primaryProps.title}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { Tooltip };
