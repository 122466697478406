import { useState, useEffect, useContext } from 'react';
import { appActions, AppContext } from 'context';
import { RouteName, goToPage } from 'routes';
import { Notification } from 'common/notification';
import { isBeta } from 'config/constants';
import { UserController } from 'networking/controllers/user-controller';

import { captureEvent } from 'posthog';
import { useParams } from 'react-router-dom';
import { Button, ButtonStyle } from 'common/button';
import { isTemporaryUser } from 'helpers/utils';
import { ModalTemporaryUser } from 'common/modal-record-email/modal-temporary-user';
import { trackCustomEvent } from 'helpers/analytics';
import { useTopMenuTour } from 'hooks/use-top-menu-tour/use-top-menu-tour';
import { OnboardingContext } from 'context/onboarding-context';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import { BetaMessage, LeftSection, RightSection } from './components';
import styles from './navbar.module.scss';

const Navbar = () => {
  const { state, dispatch } = useContext(AppContext);
  const {
    state: { onboardingPhase, topMenuTourDisplayed },
  } = useContext(OnboardingContext);
  const [showError, setShowError] = useState(false);
  const [showBetaMsg, setShowBetaMsg] = useState(isBeta);
  const [showLeaveTemporaryUserModal, setShowLeaveTemporaryUserModal] =
    useState(false);
  const { guestToken } = useParams<ParamsType>();

  const {
    activeAlbumRef,
    copyLinkRef,
    hamburgerMenuRef,
    navBarRef,
    userMenuRef,
    setEnableTourSteps,
  } = useTopMenuTour();

  const logOutUser = async () => {
    captureEvent('logout');
    trackCustomEvent('logOut');
    try {
      await UserController.signOutUser();
      dispatch({ type: appActions.SESSION_EXPIRED });
      goToPage(RouteName.SignIn);
    } catch (e) {
      setShowError(true);
    }
  };

  const authenticate = async () => {
    if (state.logged) {
      return;
    }

    try {
      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });
    } catch (e) {
      dispatch({ type: appActions.SESSION_EXPIRED });
    }
  };

  useEffect(() => {
    authenticate();
  }, []);
  useEffect(() => {
    dispatch({ type: appActions.BETA_MSG, showBetaMsg });
  }, [showBetaMsg]);
  useEffect(() => {
    UserController.addGuestTokenHeader(guestToken);
    return UserController.removeGuestTokenHeader;
  }, [guestToken]);

  const showErrorNotification = () => (
    <Notification
      message="We couldn’t log you out. Please try again."
      handleClose={() => setShowError(false)}
    />
  );

  const showNavbarToTemporaryUsers = (user: UserType) => (
    <nav id="navbar-fixed" className={styles.container}>
      {showBetaMsg && <BetaMessage onClose={() => setShowBetaMsg(false)} />}
      <div className={styles.navbar}>
        <LeftSection user={user} />
        <div className={styles.buttonBox}>
          <Button
            buttonStyle={ButtonStyle.GreyGhost}
            onClick={() => setShowLeaveTemporaryUserModal(true)}
          >
            Leave trial
          </Button>
          <Button
            onClick={() => goToPage(RouteName.SignUp)}
            buttonStyle={ButtonStyle.PrimaryGhost}
          >
            Sign up
          </Button>
        </div>
      </div>
    </nav>
  );

  const showNavbar = (user: UserType, currentAlbum?: AlbumType) => (
    <nav id="navbar-fixed" className={styles.container}>
      {showBetaMsg && <BetaMessage onClose={() => setShowBetaMsg(false)} />}
      <div ref={navBarRef} className={styles.navbar} id="nav-bar">
        <LeftSection
          user={user}
          currentAlbum={currentAlbum}
          activeAlbumRef={activeAlbumRef}
          copyLinkRef={copyLinkRef}
        />
        <RightSection
          user={user}
          logOutUser={logOutUser}
          menuRef={userMenuRef}
          menuButtonRefProp={hamburgerMenuRef}
          tourMode={
            !state.data.user.firstVisitDisplayed &&
            onboardingPhase === OnboardingPhase.topMenuTour &&
            !topMenuTourDisplayed
          }
          notifyMenuOpen={() => setEnableTourSteps(true)}
        />
      </div>
    </nav>
  );

  return (
    <>
      {showError && showErrorNotification()}
      {showLeaveTemporaryUserModal && (
        <ModalTemporaryUser
          onClose={() => setShowLeaveTemporaryUserModal(false)}
        />
      )}
      {isTemporaryUser(state.data.user)
        ? showNavbarToTemporaryUsers(state.data.user)
        : showNavbar(state.data.user, state.currentAlbum)}
    </>
  );
};

export { Navbar };
