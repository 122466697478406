import React, { useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import styles from './onboarding-message.module.scss';

type OnboardingMessageProps = {
  target: string | HTMLElement;
  title?: React.ReactNode;
  content?: React.ReactNode;
  skippable?: boolean;
  nextButton?: { text: string; action: () => void };
  backButton?: { text: string; action: () => void };
  handleSkipTutorial: () => void;
  spotlightPadding?: number;
  nextCallback?: () => void;
  backCallback?: () => void;
  position?: 'right' | 'left';
};

const OnboardingMessage: React.FC<OnboardingMessageProps> = ({
  target,
  title,
  content,
  skippable = false,
  nextButton,
  backButton,
  handleSkipTutorial,
  spotlightPadding = 0,
  nextCallback,
  backCallback,
  position,
}) => {
  const [spotlightRect, setSpotlightRect] = useState<DOMRect | undefined>(
    undefined,
  );

  const onNext = () => {
    nextButton?.action();
    nextCallback?.();
  };

  const onBack = () => {
    backButton?.action();
    backCallback?.();
  };

  useEffect(() => {
    const element =
      target instanceof HTMLElement ? target : document.querySelector(target);
    if (element) {
      const rect = element.getBoundingClientRect();
      setSpotlightRect(rect);
    }
  }, [target]);

  const clipPath = spotlightRect
    ? `polygon(
    0% 0%,
    0% 100%,
    ${spotlightRect!.left - spotlightPadding}px 100%,
    ${spotlightRect!.left - spotlightPadding}px ${spotlightRect!.top - spotlightPadding}px,
    ${spotlightRect!.left + spotlightRect!.width + spotlightPadding}px ${spotlightRect!.top - spotlightPadding}px,
    ${spotlightRect!.left + spotlightRect!.width + spotlightPadding}px ${spotlightRect!.top + spotlightRect!.height + spotlightPadding}px,
    ${spotlightRect!.left - spotlightPadding}px ${spotlightRect!.top + spotlightRect!.height + spotlightPadding}px,
    ${spotlightRect!.left - spotlightPadding}px 100%,
    100% 100%,
    100% 0%
  )`
    : '';

  const messagePosition =
    position === 'right'
      ? {
          right: '18px',
          left: 'auto',
        }
      : {
          left: '18px',
          right: 'auto',
        };

  return (
    <>
      <div className={styles.messageBackground} style={{ clipPath }}>
        <div />
      </div>
      <div className={styles.container} style={{ ...messagePosition }}>
        <div className="text__body__semi__bold__medium__textNeutral40">
          {title}
        </div>
        <div className="text__body__regular__medium__textNeutral30">
          {content}
        </div>
        <div className={styles.footer}>
          <div>
            {skippable && (
              <Button
                buttonSize={ButtonSize.Small}
                buttonStyle={ButtonStyle.GreyGhost}
                onClick={handleSkipTutorial}
              >
                Skip
              </Button>
            )}
          </div>
          <div className={styles.buttons}>
            {backButton && (
              <Button
                buttonSize={ButtonSize.Small}
                buttonStyle={ButtonStyle.PrimaryStroke}
                onClick={onBack}
              >
                {backButton.text}
              </Button>
            )}
            {nextButton && (
              <Button buttonSize={ButtonSize.Small} onClick={onNext}>
                {nextButton.text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { OnboardingMessage };
