import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';

import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import {
  AlertTriangleIcon,
  DeleteIcon,
  InfoIcon,
  PlusIcon,
} from 'assets/icons';
import {
  classnames,
  getSubscriptionSchemeById,
  isReadOnly,
  isReadOnlySubOrRestrictedAlbum,
} from 'helpers/utils';
import { LabeledText } from 'common/labeled-text';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { cloneDeep } from 'lodash';
import { InvitationStatusId } from 'common/enums';
import { RouteName, goToPage } from 'routes';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { NotificationObject } from 'models/notificationObject';
import { AppContext, appActions } from 'context';
import { HelperText } from 'common/helper-text';
import { trackCustomEvent } from 'helpers/analytics';
import { InviteToRecordProcess } from 'common/invite-to-record-process';
import { InvitationStep } from 'common/invite-to-record-process/invite-to-record-process';
import { RemoveContributorModal } from './components/remove-contributor-modal';
import { LeaveAlbumModal } from './components/leave-album-modal';

import aboutStyles from '../../about-album.module.scss';
import styles from './contributors-section.module.scss';

type ContributorsSectionProps = {
  generalDispatch: Dispatch<any>;
  album: AlbumType;
  isOwner: boolean;
  refetchAlbum: () => Promise<void>;
};

const ContributorsSection: React.FC<ContributorsSectionProps> = ({
  album,
  isOwner,
  generalDispatch,
  refetchAlbum,
}) => {
  const { state } = useContext(AppContext);

  const {
    subscriptionsScheme,
    data: { user },
  } = state;

  const [contributorsList, setContributorsList] = useState<ContributorType[]>(
    album.contributors,
  );

  const [showAddContributorModal, setShowAddContributorModal] = useState(false);
  const [contributorToDelete, setContributorToDelete] =
    useState<ContributorType | null>(null);
  const [contributorToLeaveAlbum, setContributorToLeaveAlbum] =
    useState<ContributorType | null>(null);

  const canAddContributors = () => {
    if (!isReadOnlySubOrRestrictedAlbum(album)) {
      const subSelectedInfo = getSubscriptionSchemeById(
        subscriptionsScheme!,
        album.subscriptionType,
      );

      const maxContributorsLengthAllowed =
        subSelectedInfo!.permissions?.contributors;

      if (isOwner && contributorsList.length < maxContributorsLengthAllowed!) {
        return true;
      }
    }

    return false;
  };

  const handleResendInvite = async (contriSelected: ContributorType) => {
    const contributorsCopy = cloneDeep(contributorsList);

    const contributorSelected = contributorsCopy.find(
      (contri: ContributorType) =>
        contri.invitationId === contriSelected.invitationId,
    )!;

    try {
      const contributorResponse = await AlbumsController.resendAlbumInvitation(
        contributorSelected.invitationId!,
      );

      contributorSelected.invitationResent = true;
      contributorSelected.invitationStatus =
        contributorResponse.invitationStatus;

      setContributorsList(contributorsCopy);
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Resend invitation',
          message: "Invitation couldn't be sent",
        }),
      });
    }
  };

  const showRemoveButton = (contributor: ContributorType) => (
    <div className={styles.removeButton}>
      <Button
        buttonStyle={ButtonStyle.GreyGhost}
        onClick={() => {
          setContributorToDelete(contributor);
          trackCustomEvent('startRemoveContributor');
        }}
        disabled={isReadOnly(album)}
      >
        <DeleteIcon />
      </Button>
    </div>
  );

  const showActionsAvailable = useCallback(
    (contri: ContributorType) => {
      const invitationStatusId: InvitationStatusId = contri.invitationStatus!;

      if (
        invitationStatusId === InvitationStatusId.pending &&
        !contri.invitationResent
      ) {
        return (
          <Button
            buttonSize={ButtonSize.Tiny}
            buttonStyle={ButtonStyle.SecondaryGhost}
            className={styles.successButton}
            onClick={() => handleResendInvite(contri)}
          >
            Resend invite
          </Button>
        );
      }

      if (
        invitationStatusId === InvitationStatusId.pending &&
        contri.invitationResent
      ) {
        return (
          <span
            className={classnames('text__body__regular__tiny__textNeutral20')}
          >
            {' '}
            Invite sent!
          </span>
        );
      }

      if (invitationStatusId === InvitationStatusId.rejected) {
        return (
          <div>
            <Button
              buttonSize={ButtonSize.Tiny}
              buttonStyle={ButtonStyle.GreyGhost}
              className={styles.successButton}
              onClick={() => handleResendInvite(contri)}
            >
              Invite again
            </Button>
          </div>
        );
      }

      return null;
    },
    [contributorsList],
  );

  const showInvitationStatus = (contri: ContributorType) => {
    const invitationStatusId: InvitationStatusId = contri.invitationStatus;

    if (invitationStatusId !== InvitationStatusId.accepted) {
      const text =
        invitationStatusId === InvitationStatusId.rejected
          ? 'Contributor has rejected invite. If this was a mistake you can resend the invitation.'
          : 'Contributor has not accepted invite yet.';

      const iconStyles =
        invitationStatusId === InvitationStatusId.rejected
          ? styles.dangerIconColor
          : styles.warningIconColor;

      return (
        <>
          <AlertTriangleIcon
            className={classnames(styles.generalIconStyle, iconStyles)}
          />
          <span className="text__body__regular__tiny__textNeutral30">
            {text}
          </span>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    setContributorsList(album.contributors);
  }, [album]);

  const invitationAccepted = (contri: ContributorType) =>
    contri.invitationStatus === InvitationStatusId.accepted;

  const handleDeleteContributor = (contri: ContributorType) => {
    const contributorsUpdated = contributorsList.filter(
      (c: ContributorType) => c.invitationId !== contri.invitationId,
    );

    setContributorsList(contributorsUpdated);
    refetchAlbum();
  };
  const isUserThisContributor = (contributor: ContributorType) =>
    contributor.id === user.id;

  const showLeaveAlbumButton = (contributor: ContributorType) => (
    <Button
      buttonStyle={ButtonStyle.SecondaryStroke}
      onClick={() => setContributorToLeaveAlbum(contributor)}
    >
      Leave album
    </Button>
  );

  return (
    <div
      className={classnames(globalStyles.infoCard, styles.contributorsSection)}
    >
      <p className="text__title2__textNeutral40">Contributor</p>
      <HelperText
        content={`Contributors have the ability to add, edit and remove prompts,
          record clips and join video calls for remote interviews. When you add
          a contributor, an invitation to join as a contributor to this album
          will be sent to their inbox.`}
        icon={<InfoIcon className={styles.infoIcon} />}
      />
      <div>
        {contributorsList.map((contributor) => (
          <div className={styles.contributor} key={contributor.id}>
            <div>
              <LabeledText
                className={styles.contributorInfo}
                label="Name"
                text={contributor.name}
              />
              <LabeledText
                className={styles.contributorInfo}
                label="Email"
                text={contributor.email}
              />
            </div>

            <div className={styles.actions}>
              {isOwner && !invitationAccepted(contributor) && (
                <>
                  {showInvitationStatus(contributor)}
                  {showActionsAvailable(contributor)}
                </>
              )}

              {isOwner && showRemoveButton(contributor)}

              {!isOwner &&
                isUserThisContributor(contributor) &&
                showLeaveAlbumButton(contributor)}
            </div>
          </div>
        ))}
      </div>

      {canAddContributors() && (
        <div className={aboutStyles.buttonSection}>
          <Button
            className={styles.addContributorButton}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={() => setShowAddContributorModal(true)}
          >
            <PlusIcon /> Add Contributor
          </Button>
        </div>
      )}

      {showAddContributorModal && (
        <InviteToRecordProcess
          handleClose={() => setShowAddContributorModal(false)}
          initialStep={InvitationStep.inviteContributors}
          handleFinishProcess={() => {
            refetchAlbum();
          }}
        />
      )}

      {contributorToDelete && (
        <RemoveContributorModal
          contributor={contributorToDelete}
          albumId={album.id}
          notifyDeleteContributor={(contributor: ContributorType) =>
            handleDeleteContributor(contributor)
          }
          onClose={() => setContributorToDelete(null)}
        />
      )}

      {contributorToLeaveAlbum && (
        <LeaveAlbumModal
          contributor={contributorToLeaveAlbum}
          albumId={album.id}
          notifyLeaveAlbum={() => goToPage(RouteName.Albums)}
          onClose={() => setContributorToLeaveAlbum(null)}
        />
      )}
    </div>
  );
};

export { ContributorsSection };
