import ReactDOM from 'react-dom';

import { CrossIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';

import { useEffect } from 'react';
import styles from './modal.module.scss';

type ModalProps = {
  className?: string;
  contentClassName?: string;
  children: JSX.Element | JSX.Element[];
  disableManualClosing?: boolean;
  onClose?: () => void;
  title?: string;
};

const Modal = ({
  className = '',
  contentClassName = '',
  children,
  onClose,
  title = '',
  disableManualClosing = false,
}: ModalProps) => {
  const showCloseButton = () => (
    <button
      aria-label="Close"
      className={styles.headerIcon}
      type="button"
      onClick={onClose}
    >
      <CrossIcon />
    </button>
  );

  useEffect(() => {
    window.document.body.style.overflow = 'hidden';

    return () => {
      window.document.body.style.overflow = 'auto';
    };
  });

  return ReactDOM.createPortal(
    <div
      className={classnames(
        className,
        styles.modal,
        styles.openAnimation,
        styles.modalButtons,
      )}
    >
      <div className={styles.modalBackground} />
      <div className={classnames(styles.modalContent, contentClassName)}>
        <div className={styles.header}>
          <div className={classnames(styles.headerTitle)}>{title}</div>
          {!disableManualClosing && showCloseButton()}
        </div>
        {children}
      </div>
    </div>,
    document.getElementById('modal') as HTMLElement,
  );
};

export { Modal };
