import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { useCallback, useContext, useMemo } from 'react';

import {
  VideoIcon,
  VideoDisabledIcon,
  MicIcon,
  MicDisabledIcon,
} from 'assets/icons';
import { SensorType } from 'context/sensor-selection-context/context-reducer';
import { SensorSelectionContext } from 'context';
import { classnames } from 'helpers/utils';
import { SensorSelectionActions } from 'context/sensor-selection-context/action-types';
import styles from './sensor-toggle-button.module.scss';

type SensorToggleButtonProps = {
  sensorType: SensorType;
  buttonSize?: ButtonSize;
  disabled?: boolean;
  iconClass?: string;
};

const SensorToggleButton = ({
  sensorType,
  buttonSize = ButtonSize.Small,
  disabled = false,
  iconClass = '',
}: SensorToggleButtonProps) => {
  const { sensorSelection, updateSensorSelection } = useContext(
    SensorSelectionContext,
  );

  const toggleSensorEnabled = useCallback(() => {
    const actionType =
      sensorType === 'camera'
        ? SensorSelectionActions.CAMERA_SELECTED
        : SensorSelectionActions.MICROPHONE_TOGGLED;
    updateSensorSelection({
      type: actionType,
      enabled: !sensorSelection[sensorType].enabled,
    });
  }, [sensorSelection, updateSensorSelection]);

  const [enabledIcon, disabledIcon] = useMemo(() => {
    if (sensorType === 'camera') {
      return [
        <VideoIcon
          className={
            (classnames(styles.sensorEnabled), iconClass || styles.icon)
          }
        />,
        <VideoDisabledIcon
          className={classnames(
            styles.sensorDisabled,
            iconClass || styles.icon,
          )}
        />,
      ];
    }

    return [
      <MicIcon
        className={(classnames(styles.sensorEnabled), iconClass || styles.icon)}
      />,
      <MicDisabledIcon
        className={classnames(styles.sensorDisabled, iconClass || styles.icon)}
      />,
    ];
  }, [sensorType]);

  return (
    <div className={styles.sensorToggleButton}>
      <Button
        key={sensorType}
        buttonSize={buttonSize}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => toggleSensorEnabled()}
        disabled={disabled || !!sensorSelection[sensorType].error}
        className={styles.button}
      >
        {sensorSelection[sensorType].enabled ? enabledIcon : disabledIcon}
      </Button>
    </div>
  );
};

export { SensorToggleButton };
