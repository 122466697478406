import React, { ReactNode, RefObject, useEffect, useRef } from 'react';
import { classnames } from 'helpers/utils';
import styles from './menu.module.scss';

type MenuProps = {
  width?: string;
  position?: string;
  className?: string;
  children: ReactNode;
  alwaysVisible?: boolean;
  menuRef?: RefObject<HTMLDivElement>;
};

enum PositionType {
  BottomRightAligned = 'bottomRightAligned',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  TopLeft = 'topLeftLeft',
}

function handleClassStyle(position: string, width: string) {
  const classes = [styles.menuContainer, width];

  switch (position) {
    case PositionType.BottomRightAligned:
      classes.push(styles.bottomRight);
      break;
    case PositionType.BottomLeft:
      classes.push(styles.bottomLeft);
      break;
    case PositionType.BottomRight:
      classes.push(styles.bottomRight);
      break;
    case PositionType.TopLeft:
      classes.push(styles.topLeft);
      break;
    default:
      break;
  }

  return classnames(...classes);
}

const Menu: React.FC<MenuProps> = ({
  position = PositionType.BottomLeft,
  width = '',
  className = '',
  children,
  alwaysVisible = false,
  menuRef,
}) => {
  const ref = menuRef ?? useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref?.current && alwaysVisible) {
      ref?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [alwaysVisible, ref]);

  return (
    <div
      className={classnames(handleClassStyle(position, width), className)}
      ref={ref}
      id="menu"
    >
      {children}
    </div>
  );
};

export { Menu, PositionType };
