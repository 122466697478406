const onboardingActions = {
  SET_ONBOARDING_PHASE: 'SET_ONBOARDING_PHASE',
  SET_INVITE_TO_RECORD_PROPS: 'SET_INVITE_TO_RECORD_PROPS',
  SET_RECORD_TO_SOMEONE_ELSE_PROPS: 'SET_RECORD_TO_SOMEONE_ELSE_PROPS',
  SET_TOUR_STEPS: 'SET_TOUR_STEPS',
  SET_PRODUCT_TOUR_DISPLAYED: 'SET_PRODUCT_TOUR_DISPLAYED',
  SET_ALBUM_MENU_TOUR_DISPLAYED: 'SET_ALBUM_MENU_TOUR_DISPLAYED',
  SET_TOP_MENU_TOUR_DISPLAYED: 'SET_TOP_MENU_TOUR_DISPLAYED',
};

export { onboardingActions };
