import { SelfRecordingStatus } from 'common/enums';
import { selfRecordingActions } from './action-types';

export type VideosRecordedType = {
  requestId: string;
  videoId: number;
  videoUrl: string;
  status: SelfRecordingStatus;
  description?: string;
  name: string;
  isAudioOnly?: boolean;
  imageId?: string;
  file?: File;
  fileName?: string;
  uploadStartTime?: Date;
  videoSignedId: string;
  positionInBatchToClip?: number;
};

export type UploadMode = 'record' | 'upload';

export type InitialStateType = {
  videosRecorded: VideosRecordedType[];
  isRecording: boolean;
  selectedQuestion?: ClipType;
  albumId: number;
  readyForNextStep: boolean;
  mode?: UploadMode;
  mediaStream?: MediaStream;
  isUploading: boolean;
  batchId?: string;
  clipNumber: number;
};

const initialState: InitialStateType = {
  videosRecorded: [],
  isRecording: false,
  selectedQuestion: undefined,
  albumId: 0,
  readyForNextStep: false,
  mediaStream: undefined,
  isUploading: false,
  batchId: undefined,
  clipNumber: 0,
};

type InformationToUpdate = {
  requestId: string;
  description: string;
  name: string;
  imageId: string;
  file?: File;
  status?: SelfRecordingStatus;
};

type AudioOnlyType = {
  requestId: string;
  isAudioOnly: boolean;
};

type UpdatePositionInBatch = {
  requestId: string;
  positionInBatch: number;
};

type Action = {
  type: string;
  videoToAppend: VideosRecordedType;
  selectedQuestion: ClipType;
  videoToDelete: string;
  albumId: number;
  updateInformation: InformationToUpdate;
  idWhoseVideoFailed: string;
  mode?: UploadMode;
  mediaStream: MediaStream;
  isRecording: boolean;
  isUploading: boolean;
  updateAudioOnly: AudioOnlyType;
  positionInBatchToClip: UpdatePositionInBatch;
  clipNumber: number;
};

const SelfRecordingReducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case selfRecordingActions.addVideo:
      return {
        ...state,
        videosRecorded: state.videosRecorded.map((video) => {
          if (video.requestId === action.videoToAppend.requestId) {
            return {
              ...video,
              videoId: action.videoToAppend.videoId,
              videoUrl: action.videoToAppend.videoUrl,
              videoSignedId: action.videoToAppend.videoSignedId,
              status: SelfRecordingStatus.recorded,
            };
          }
          return video;
        }),
      };
    case selfRecordingActions.setIsRecording:
      return {
        ...state,
        isRecording: action.isRecording,
      };
    case selfRecordingActions.setIsUploading:
      return {
        ...state,
        isUploading: action.isUploading,
      };
    case selfRecordingActions.setSelectedQuestion:
      return {
        ...state,
        selectedQuestion: action.selectedQuestion,
      };
    case selfRecordingActions.deleteAllVideos:
      return {
        ...state,
        videosRecorded: [],
        readyForNextStep: false,
      };
    case selfRecordingActions.deleteVideo:
      return {
        ...state,
        videosRecorded: state.videosRecorded.filter(
          (video) => video.requestId !== action.videoToDelete,
        ),
      };
    case selfRecordingActions.setAlbumId:
      return {
        ...state,
        albumId: action.albumId,
      };
    case selfRecordingActions.newClip:
      return {
        ...state,
        videosRecorded: state.videosRecorded.concat(action.videoToAppend),
      };
    case selfRecordingActions.setReadyForNextStep:
      return {
        ...state,
        readyForNextStep:
          state.videosRecorded.length > 0
            ? state.videosRecorded.every(
                (video) => video.status === SelfRecordingStatus.recorded,
              )
            : false,
      };
    case selfRecordingActions.updateInformation:
      return {
        ...state,
        videosRecorded: state.videosRecorded.map((video) => {
          if (video.requestId === action.updateInformation.requestId) {
            return {
              ...video,
              description: action.updateInformation.description
                ? action.updateInformation.description
                : video.description,
              name: action.updateInformation.name
                ? action.updateInformation.name
                : video.name,
              imageId: action.updateInformation.imageId
                ? action.updateInformation.imageId
                : video.imageId,
              file: action.updateInformation.file
                ? action.updateInformation.file
                : video.file,
              status: action.updateInformation.status
                ? action.updateInformation.status
                : video.status,
            };
          }
          return video;
        }),
      };
    case selfRecordingActions.updateAudioOnly:
      return {
        ...state,
        videosRecorded: state.videosRecorded.map((video) => {
          if (video.requestId === action.updateAudioOnly.requestId) {
            return {
              ...video,
              isAudioOnly: action.updateAudioOnly.isAudioOnly,
            };
          }
          return video;
        }),
      };
    case selfRecordingActions.videoFailed:
      return {
        ...state,
        videosRecorded: state.videosRecorded.map((video) => {
          if (video.requestId === action.idWhoseVideoFailed) {
            return {
              ...video,
              status: SelfRecordingStatus.error,
            };
          }
          return video;
        }),
      };
    case selfRecordingActions.setMode:
      return {
        ...state,
        mode: action.mode,
      };
    case selfRecordingActions.setMediaStream:
      return {
        ...state,
        mediaStream: action.mediaStream,
      };
    case selfRecordingActions.cleanState: {
      state.mediaStream?.getTracks().forEach((track) => {
        track.stop();
      });
      return initialState;
    }
    case selfRecordingActions.setBatchId:
      return {
        ...state,
        batchId: Math.random().toString(36).substr(2, 10),
      };
    case selfRecordingActions.setPositionInBatchToClip:
      return {
        ...state,
        videosRecorded: state.videosRecorded.map((video) => {
          if (video.requestId === action.positionInBatchToClip.requestId) {
            return {
              ...video,
              positionInBatchToClip:
                action.positionInBatchToClip.positionInBatch,
            };
          }
          return video;
        }),
      };
    case selfRecordingActions.setClipNumber:
      return {
        ...state,
        clipNumber: action.clipNumber,
      };
    default:
      return state;
  }
};

export { initialState, SelfRecordingReducer };
