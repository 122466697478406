/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AppContext, appActions } from 'context';
import { NavSections } from 'common/enums';
import { classnames } from 'helpers/utils';
import { Spinner } from 'common/spinner';
import { ContributorController } from 'networking/controllers/contributor.controller';
import { NotificationObject } from 'models/notificationObject';
import { cloneDeep, debounce } from 'lodash';
import { HelpInfoSections } from 'context/app-context/context-reducer';
import {
  ContributorsInitialState,
  ContributorsReducer,
} from './contributors-reducer';
import { ContributorsFilters } from './components/contributors-filters';
import { ContributorsTable } from './components/contributors-table';
import { NoContributorsFound } from './components/no-contributors-found';
import styles from './contributors.module.scss';

const Contributors: React.FC = () => {
  const { dispatch: generalDispatch } = useContext(AppContext);
  const [contributorsState, contributorsDispatch] = useReducer(
    ContributorsReducer,
    ContributorsInitialState,
  );

  const { queryParams, pagination, isLoading, contributorsList } =
    contributorsState;

  const didMount = useRef(false);
  const elementId = useRef<number>(1);
  const concatNextContributors = useRef(false);

  const giveUniqueIdToEachRow = (data: ContributorElementType[]) => {
    data.forEach((elem: ContributorElementType) => {
      elem.idAux = elementId.current;

      elementId.current += 1;
    });
  };

  const fetchContributors = async () => {
    try {
      if (!didMount.current) {
        delete queryParams.search;
      }

      const { data, pagination: paginationResponse } =
        await ContributorController.getContributorsList(queryParams);

      giveUniqueIdToEachRow(data);

      let newContributors = data;

      if (concatNextContributors.current) {
        const actualContributors = cloneDeep(contributorsList);
        newContributors = actualContributors.concat(newContributors);
      }

      contributorsDispatch({
        type: 'UPDATE_CONTRIBUTORS',
        contributorsList: newContributors,
      });
      contributorsDispatch({
        type: 'UPDATE_PAGINATION',
        pagination: paginationResponse,
      });
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Contributors Information',
          message: 'Error getting contributors',
        }),
      });
    } finally {
      if (!didMount.current) {
        didMount.current = true;
        contributorsDispatch({ type: 'IS_LOADING_PAGE', isLoading: false });
      }
      contributorsDispatch({
        type: 'IS_SEARCHING_CONTRIBUTORS',
        isSearching: false,
      });
    }
  };

  useEffect(() => {
    generalDispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.contributors,
    });
    generalDispatch({
      type: appActions.SET_HELP_SECTION,
      helpSection: HelpInfoSections.contributors,
    });
    fetchContributors();

    return () => {
      generalDispatch({
        type: appActions.SET_HELP_SECTION,
      });
    };
  }, []);

  useEffect(() => {
    contributorsDispatch({
      type: 'IS_SEARCHING_CONTRIBUTORS',
      isSearching: true,
    });
    fetchContributors();
  }, [queryParams]);

  const handleDebounceFn = (newValue: string) => {
    const params = { ...queryParams };
    if (newValue === '') delete params.search;
    else params.search = newValue;

    contributorsDispatch({
      type: 'UPDATE_QUERY_PARAMS',
      queryParams: { ...params, pageNum: 1 },
    });
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 800), [
    queryParams,
  ]);

  const handleChangeSearch = (newValue: string) => {
    concatNextContributors.current = false;
    contributorsDispatch({
      type: 'UPDATE_SEARCH_VALUE',
      searchValue: newValue,
    });

    debounceFn(newValue);
  };

  const handleClickSortBy = (option: SelectType) => {
    concatNextContributors.current = false;
    contributorsDispatch({
      type: 'UPDATE_QUERY_PARAMS',
      queryParams: { ...queryParams, sortBy: option, pageNum: 1 },
    });
  };

  const handleGetMoreContributors = () => {
    concatNextContributors.current = true;
    contributorsDispatch({
      type: 'UPDATE_QUERY_PARAMS',
      queryParams: { ...queryParams, pageNum: pagination.currentPage + 1 },
    });
  };

  const handleAllNewFiltersSelected = (
    allQueryParams: ContributorsQueryParamsType,
  ) => {
    concatNextContributors.current = false;
    contributorsDispatch({
      type: 'UPDATE_QUERY_PARAMS',
      queryParams: { ...allQueryParams, pageNum: 1 },
    });
  };

  const showContributorsTable = () => (
    <ContributorsTable
      contributorsState={contributorsState}
      contributorsDispatch={contributorsDispatch}
      notifyGetMoreContributors={() => handleGetMoreContributors()}
    />
  );

  const showEmptyPage = () => <NoContributorsFound />;

  const showContentView = () => (
    <>
      <div className={styles.titleAndFilters}>
        <div
          className={classnames('text__heading4__textNeutral40', styles.title)}
        >
          {' '}
          Contributors{' '}
        </div>
        <ContributorsFilters
          contributorsState={contributorsState}
          className={styles.contributorsFilters}
          notifyClickSortBy={(option: SelectType) => handleClickSortBy(option)}
          notifyChangeSearch={(newValue: string) =>
            handleChangeSearch(newValue)
          }
          notifyAllNewFiltersSelected={(
            allQueryParams: ContributorsQueryParamsType,
          ) => handleAllNewFiltersSelected(allQueryParams)}
        />
      </div>

      {contributorsList.length ? showContributorsTable() : showEmptyPage()}
    </>
  );

  return (
    <div className={classnames(globalStyles.loggedMainContainer)}>
      <div className={styles.contributorsContent}>
        {!isLoading ? showContentView() : <Spinner />}
      </div>
    </div>
  );
};

export { Contributors };
