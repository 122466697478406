import React, { useState } from 'react';
import { Modal } from 'common/modal';
import PlaceholderVideo from 'assets/images/product-tour-video-placeholder.png';
import PlaceholderImage from 'assets/images/product-tour-image-placeholder.png';
import AddPromptsIllustration from 'assets/images/product-tour-planning.png';
import InviteCollaboratorsIllustration from 'assets/images/product-tour-collaboration.png';
import RecordVideosIllustration from 'assets/images/product-tour-recording.png';
import WelcomeIllustration from 'assets/images/product-tour-welcome.png';
import StoreDocumentsIllustration from 'assets/images/product-tour-store-documents.png';
import SharingAndPrivacyIllustration from 'assets/images/product-tour-sharing-and-privacy.png';
import { Button, ButtonStyle } from 'common/button';
import { classnames } from 'helpers/utils';
import { CrossIcon } from 'assets/icons';
import styles from './product-tour-carousel.module.scss';

enum ProductTourStepsEnum {
  welcome = 'welcome',
  addPrompts = 'addPrompts',
  inviteCollaborators = 'inviteCollaborators',
  recordVideos = 'recordVideos',
  storeDocuments = 'storeDocuments',
  sharingAndPrivacy = 'sharingAndPrivacy',
}

type ProductTourStepContent = {
  image?: string;
  placeholderImage: string;
  title: string;
  description: string | React.ReactNode;
  nextStep?: ProductTourStepsEnum;
  backStep?: ProductTourStepsEnum;
  skippable?: boolean;
};

type ProductTourSteps = {
  [key in ProductTourStepsEnum]: ProductTourStepContent;
};

const productTourSteps: ProductTourSteps = {
  [ProductTourStepsEnum.welcome]: {
    image: WelcomeIllustration,
    placeholderImage: PlaceholderVideo,
    title: 'Welcome to Generational Story',
    description: (
      <p>
        You can use Generational Story{' '}
        <span className="text__body__semi__bold__medium__primary50">
          to record your or someone else’s life story
        </span>
        ,{' '}
        <span className="text__body__semi__bold__medium__primary50">
          preserve family videos
        </span>{' '}
        and{' '}
        <span className="text__body__semi__bold__medium__primary50">
          images
        </span>
        ,{' '}
        <span className="text__body__semi__bold__medium__primary50">
          capture memories from an event
        </span>
        , and more! The possibilities are endless with our flexible set of
        features.
      </p>
    ),
    nextStep: ProductTourStepsEnum.addPrompts,
    skippable: true,
  },
  [ProductTourStepsEnum.addPrompts]: {
    image: AddPromptsIllustration,
    placeholderImage: PlaceholderImage,
    title: 'Pre-plan your album & set reminders',
    description: (
      <p>
        If you need ideas for your story recording, plan your album in advance
        by{' '}
        <span className="text__body__semi__bold__medium__primary50">
          adding prompts
        </span>
        . Choose from our curated categories or create your own. Once your album
        is planned,{' '}
        <span className="text__body__semi__bold__medium__primary50">
          set up reminders
        </span>{' '}
        to send scheduled prompts to you or contributors.
      </p>
    ),
    nextStep: ProductTourStepsEnum.inviteCollaborators,
    backStep: ProductTourStepsEnum.welcome,
  },
  [ProductTourStepsEnum.inviteCollaborators]: {
    image: InviteCollaboratorsIllustration,
    placeholderImage: PlaceholderImage,
    title: 'Make your album collaborative',
    description: (
      <p>
        Want to interview someone or collect memories? Invite family and friends
        to collaborate. You can give them{' '}
        <span className="text__body__semi__bold__medium__primary50">
          admin access
        </span>{' '}
        to manage the album or{' '}
        <span className="text__body__semi__bold__medium__primary50">
          guest access
        </span>{' '}
        to contribute content.
      </p>
    ),
    nextStep: ProductTourStepsEnum.recordVideos,
    backStep: ProductTourStepsEnum.addPrompts,
  },
  [ProductTourStepsEnum.recordVideos]: {
    image: RecordVideosIllustration,
    placeholderImage: PlaceholderImage,
    title: 'Record videos in person or through video call',
    description: (
      <p>
        You can{' '}
        <span className="text__body__semi__bold__medium__primary50">
          record videos
        </span>{' '}
        in several ways: solo on the web app, via the mobile app for more
        flexibility, or with someone on a{' '}
        <span className="text__body__semi__bold__medium__primary50">
          video call
        </span>
        . Feeling camera shy? Make your clips{' '}
        <span className="text__body__semi__bold__medium__primary50">
          audio only
        </span>{' '}
        and{' '}
        <span className="text__body__semi__bold__medium__primary50">
          upload images
        </span>{' '}
        for playback.
      </p>
    ),
    nextStep: ProductTourStepsEnum.sharingAndPrivacy, // TODO: Change this to storeDocuments when enabling the upload feature
    backStep: ProductTourStepsEnum.inviteCollaborators,
  },
  [ProductTourStepsEnum.storeDocuments]: {
    image: StoreDocumentsIllustration,
    placeholderImage: PlaceholderImage,
    title: 'Store document and image files',
    description: (
      <p>
        Upload{' '}
        <span className="text__body__semi__bold__medium__primary50">
          journals
        </span>
        ,{' '}
        <span className="text__body__semi__bold__medium__primary50">
          scrapbooks
        </span>
        ,{' '}
        <span className="text__body__semi__bold__medium__primary50">
          family videos
        </span>{' '}
        and{' '}
        <span className="text__body__semi__bold__medium__primary50">
          photos
        </span>{' '}
        - or{' '}
        <span className="text__body__semi__bold__medium__primary50">
          take pictures and write entries directly into the app
        </span>{' '}
        for safekeeping and easy{' '}
        <span className="text__body__semi__bold__medium__primary50">
          AI-powered search
        </span>
        .
      </p>
    ),
    nextStep: ProductTourStepsEnum.sharingAndPrivacy,
    backStep: ProductTourStepsEnum.recordVideos,
  },
  [ProductTourStepsEnum.sharingAndPrivacy]: {
    image: SharingAndPrivacyIllustration,
    placeholderImage: PlaceholderImage,
    title: 'Sharing and Privacy',
    description: (
      <p>
        Manage what you share.{' '}
        <span className="text__body__semi__bold__medium__primary50">
          Post a single video, share a whole album
        </span>
        , or{' '}
        <span className="text__body__semi__bold__medium__primary50">
          set specific sections as private
        </span>
        . Link clips or entire albums to{' '}
        <span className="text__body__semi__bold__medium__primary50">
          QR codes
        </span>{' '}
        , which can be placed on books, mementos, or headstones.
      </p>
    ),
    backStep: ProductTourStepsEnum.recordVideos, // TODO: Change this to storeDocuments when enabling the upload feature
  },
};

type ProductTourCarouselProps = {
  handleClose: () => void;
};

const ProductTourCarousel: React.FC<ProductTourCarouselProps> = ({
  handleClose,
}) => {
  const [step, setStep] = useState(ProductTourStepsEnum.welcome);

  const {
    image,
    placeholderImage,
    title,
    description,
    nextStep,
    backStep,
    skippable,
  } = productTourSteps[step];

  return (
    <Modal contentClassName={styles.container} disableManualClosing>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.transparent} />
          <div className={styles.pagination}>
            {Object.keys(productTourSteps).map((tourStep) => (
              <div
                className={classnames(styles.paginationDot, {
                  [styles.paginationDotGreen]: tourStep === step,
                })}
              />
            ))}
          </div>
          <button
            aria-label="Close"
            className={styles.headerIcon}
            type="button"
            onClick={handleClose}
          >
            <CrossIcon />
          </button>
        </div>
        <img
          alt="Step illustration"
          className={classnames(styles.image, {
            [styles.placeholder]: !image,
          })}
          src={image ?? placeholderImage}
        />
        <div className="text__heading3__primary50">{title}</div>
        <div className="text__body__regular__medium__textNeutral30">
          {description}
        </div>
        <div className={styles.footer}>
          <div className={styles.checkbox}>
            {skippable && (
              <Button buttonStyle={ButtonStyle.GreyGhost} onClick={handleClose}>
                Skip
              </Button>
            )}
          </div>
          <div className={styles.buttons}>
            {backStep && (
              <Button
                buttonStyle={ButtonStyle.GreyGhost}
                onClick={() => setStep(backStep)}
              >
                Back
              </Button>
            )}
            <Button
              onClick={() => {
                if (nextStep) {
                  setStep(nextStep);
                } else {
                  handleClose();
                }
              }}
            >
              {nextStep ? 'Next' : 'Got it'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ProductTourCarousel };
