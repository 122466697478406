import { useContext, useEffect, useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { StoreContext } from 'context/store-context/context';
import qrImage from 'assets/images/qrcode.png';
import { AppContext, appActions } from 'context/app-context';
import { NavSections } from 'common/enums';
import { classnames } from 'helpers/utils';
import { StoreController } from 'networking/controllers/store-controller';
import { HelpInfoSections } from 'context/app-context/context-reducer';
import {
  CheckoutSteps,
  StorePages,
} from '../../context/store-context/store-reducer';
import { CardItem } from './components/list';
import { Checkout } from './components/checkout';
import { Cart } from './components/cart';
import styles from './store.module.scss';

const Store: React.FC = () => {
  const { state: storeState, dispatch: storeDispatch } =
    useContext(StoreContext);

  const { dispatch } = useContext(AppContext);
  const [items, setItems] = useState<StoreItem[]>([]);
  const handleItems = async () => {
    const { data } = await StoreController.getStoreItems();
    setItems(data);
  };
  const showSelectItemsPage = () => (
    <div
      className={classnames(
        globalStyles.loggedMainContainer,
        styles.fullContent,
      )}
    >
      <div className={classnames(styles.storeList)}>
        <div className={classnames(styles.storeTitleAndSubtitle)}>
          <div className={classnames('text__heading4__textNeutral40')}>
            {' '}
            Store
          </div>
          <div
            className={classnames('text__body__regular__medium__textNeutral30')}
          >
            Preserve & share your memories with the world. More items coming
            soon!
          </div>
        </div>
        {items.map((item: StoreItem) => (
          <CardItem
            image={item.image ?? qrImage}
            name={item.name}
            description={item.description}
            price={item.price}
            id={item.id}
            state={storeState}
            storeDispatch={storeDispatch}
            key={item.id}
            handleSetImageToShow={() => {
              dispatch({
                type: appActions.SET_IMAGE_TO_ENLARGE,
                imageToEnlarge: item.image ?? qrImage,
              });
            }}
          />
        ))}
      </div>
      <div className={classnames(styles.storeCart)}>
        <Cart state={storeState} dispatch={storeDispatch} />
      </div>
    </div>
  );

  useEffect(() => {
    dispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.store,
    });
    dispatch({
      type: appActions.SET_HELP_SECTION,
      helpSection: HelpInfoSections.store,
    });
    // Clean the state when the user goes back to the store page after payment
    if (
      storeState.actualPage === StorePages.checkout &&
      storeState.checkoutStep === CheckoutSteps.paymentSummary
    ) {
      storeDispatch({ type: 'GO_INITIAL_STATE' });
    }
    handleItems();

    return () => {
      dispatch({
        type: appActions.SET_HELP_SECTION,
      });
    };
  }, []);

  if (storeState.actualPage === StorePages.selectItems) {
    return showSelectItemsPage();
  }
  return <Checkout state={storeState} dispatch={storeDispatch} />;
};

export { Store };
