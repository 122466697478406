import React, { useContext, useState } from 'react';
import RecordMyLifeImage from 'assets/images/record-tutorial.png';
import JournalingImage from 'assets/images/journaling-tutorial.png';
import RecordSomeoneElseImage from 'assets/images/record-someone-else-tutorial.png';
import UploadVideosImage from 'assets/images/upload-videos-tutorial.png';
import UploadDocumentsImage from 'assets/images/upload-documents-tutorial.png';
import PreserveMemoriesImage from 'assets/images/preserve-memories-tutorial.png';
import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import { LocalStorageApi } from 'helpers/local-storage';
import {
  PickTutorialsModal,
  Tutorials,
  TutorialsEnum,
} from './pick-tutorials-modal';
import { SelectedTutorialsModal } from './selected-tutorials-modal';
import { JournalingModal } from './journaling-modal';
import { EventsModal } from './events-modal';
import { EventReasonModal } from './event-reason-modal';

type PickTutorialsProps = {
  handleSkipTutorial: () => void;
};

const PickTutorials: React.FC<PickTutorialsProps> = ({
  handleSkipTutorial,
}) => {
  const { dispatch } = useContext(OnboardingContext);
  const [selectedTutorials, setSelectedTutorials] = useState<TutorialsEnum[]>(
    [],
  );
  const [showPickTutorialsModal, setShowPickTutorialsModal] = useState(true);
  const [showSelectedTutorialsModal, setShowSelectedTutorialsModal] =
    useState(false);
  const [showJournalingModal, setShowJournalingModal] = useState(false);
  const [showEventsModal, setShowEventsModal] = useState(false);
  const [showEventReasonModal, setShowEventReasonModal] = useState(false);

  const tutorials: Tutorials = {
    [TutorialsEnum.recordMyLife]: {
      name: 'Record my life story',
      image: RecordMyLifeImage,
      action: () => {
        // Navigate to "Record tutorial"
      },
    },
    [TutorialsEnum.journaling]: {
      name: 'Journaling',
      image: JournalingImage,
      action: () => {
        setShowSelectedTutorialsModal(false);
        setShowJournalingModal(true);
      },
    },
    [TutorialsEnum.recordSomeoneElse]: {
      name: 'Record someone else’s story',
      image: RecordSomeoneElseImage,
      action: () => {
        dispatch({
          type: onboardingActions.SET_ONBOARDING_PHASE,
          onboardingPhase: OnboardingPhase.recordSomeoneElseStory,
        });
        LocalStorageApi.set(
          'onboarding_phase',
          OnboardingPhase.recordSomeoneElseStory,
        );
      },
    },
    [TutorialsEnum.uploadVideos]: {
      name: 'Upload family videos',
      image: UploadVideosImage,
      action: () => {
        // Navigate to "Upload tutorial"
      },
    },
    [TutorialsEnum.uploadDocuments]: {
      name: 'Upload documents & images',
      image: UploadDocumentsImage,
      action: () => {
        // Navigate to "Upload tutorial"
      },
    },
    [TutorialsEnum.preserveMemories]: {
      name: 'Preserve memories from an event',
      image: PreserveMemoriesImage,
      action: () => {
        setShowSelectedTutorialsModal(false);
        setShowEventsModal(true);
      },
    },
  };

  const onSelectTutorial = (tutorial: TutorialsEnum) => {
    if (selectedTutorials.includes(tutorial as TutorialsEnum)) {
      setSelectedTutorials((prevState) =>
        prevState.filter((item) => item !== tutorial),
      );
    } else {
      setSelectedTutorials((prevState) => [
        ...prevState,
        tutorial as TutorialsEnum,
      ]);
    }
  };

  const getSelectedTutorialsSerialized = (items: TutorialsEnum[]) =>
    items.map((item) => tutorials[item]);

  return (
    <div>
      {showPickTutorialsModal && (
        <PickTutorialsModal
          tutorials={tutorials}
          selectedTutorials={selectedTutorials}
          handleClickTutorial={onSelectTutorial}
          handleClickNext={() => {
            setShowPickTutorialsModal(false);
            if (selectedTutorials.length > 1) {
              setShowSelectedTutorialsModal(true);
            } else {
              tutorials[selectedTutorials[0]].action();
            }
          }}
          handleClickSkip={() => {
            handleSkipTutorial();
            setShowPickTutorialsModal(false);
          }}
        />
      )}
      {showSelectedTutorialsModal && (
        <SelectedTutorialsModal
          selectedTutorials={getSelectedTutorialsSerialized(selectedTutorials)}
        />
      )}
      {showJournalingModal && <JournalingModal />}
      {showEventsModal && (
        <EventsModal
          handleSelectEvent={() => {
            setShowEventsModal(false);
            setShowEventReasonModal(true);
          }}
        />
      )}
      {showEventReasonModal && <EventReasonModal />}
    </div>
  );
};

export { PickTutorials };
