/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AlbumTabs, Breakpoints } from 'common/enums';
import { classnames } from 'helpers/utils';
import { ChevronIcon } from 'assets/icons';
import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { Menu } from 'common/menu';
import { useMediaQuery } from 'hooks/use-media-query';
import { genericContainerPadding } from 'config/constants';
import { useClickOutside } from 'hooks/click-outside';
import styles from './album-tab.module.scss';

type OptionMenuProps = {
  option: OptionMenuType;
};

export type OptionMenuType = {
  name: string;
  icon: ReactNode;
  handleOptionSelected: () => void;
};

const OptionMenu: React.FC<OptionMenuProps> = ({ option }) => (
  <button
    type="button"
    onClick={option.handleOptionSelected}
    className={classnames(
      'text__body__regular__medium__textNeutral30',
      styles.optionMenu,
    )}
  >
    {option.icon}
    {option.name}
  </button>
);

type AlbumTabMenuProps = {
  tab: AlbumTabs;
  selectedTab: AlbumTabs;
  disabled?: boolean;
  menuOptions: OptionMenuType[];
  scrollContainerRef?: RefObject<HTMLDivElement>;
  menuRefProp?: RefObject<HTMLDivElement>;
  tabRefProp?: RefObject<HTMLDivElement>;
  tourMode?: boolean;
  notifyMenuOpen?: () => void;
};

const AlbumTabMenu = ({
  tab,
  selectedTab,
  disabled = false,
  menuOptions,
  scrollContainerRef,
  menuRefProp,
  tabRefProp,
  tourMode,
  notifyMenuOpen,
}: AlbumTabMenuProps) => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const tabRef = tabRefProp ?? useRef<HTMLDivElement>(null);
  const menuRef = menuRefProp ?? useRef<HTMLDivElement>(null);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);

  useClickOutside(
    menuRef,
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (tabRef.current && !tabRef.current.contains(e.target as HTMLElement)) {
        if (!mobile && !tourMode) setVisibleMenu(false);
      }
    },
  );

  const onClickTab = () => {
    setVisibleMenu(!visibleMenu);
  };

  const showMenu = () => (
    <Menu
      width={styles.menuWidth}
      className={classnames(styles.menuPosition)}
      menuRef={menuRef}
    >
      {menuOptions.map((option) => (
        <OptionMenu option={option} />
      ))}
    </Menu>
  );

  const updateModalPosition = () => {
    if (tabRef.current) {
      // Start position of the tab relative to the screen
      const { left: tabStartPosition } = tabRef.current.getBoundingClientRect();
      // Start position of the tab bar relative to the screen
      const tabBarStartPosition = scrollContainerRef?.current
        ? scrollContainerRef.current.getBoundingClientRect().left
        : 0;

      // This variable contains the start position of the tab relative to the tab bar
      const left = tabStartPosition - tabBarStartPosition;

      const {
        screen: { availWidth },
      } = window;

      const menuElement = document.querySelector(`.${styles.menuPosition}`);
      if (menuElement) {
        const menuWidth = menuRef.current
          ? menuRef.current.getBoundingClientRect().width
          : 0;

        // If the menu is off-screen, it clamps it to the right edge.
        const newPosition =
          left + menuWidth > availWidth
            ? availWidth - menuWidth - genericContainerPadding
            : left;

        (menuElement as HTMLDivElement).style.setProperty(
          '--position-left',
          `${newPosition}px`,
        );
      }
    }
  };

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.addEventListener(
        'scroll',
        updateModalPosition,
      );
    }

    return () => {
      if (scrollContainerRef?.current) {
        scrollContainerRef.current.removeEventListener(
          'scroll',
          updateModalPosition,
        );
      }
    };
  }, [scrollContainerRef, mobile]);

  useEffect(() => {
    updateModalPosition();
  }, [visibleMenu]);

  useEffect(() => {
    if (tourMode && !visibleMenu) {
      setVisibleMenu(true);
      notifyMenuOpen?.();
    }
  }, [tourMode]);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        ref={tabRef}
        className={classnames(
          styles.albumTab,
          {
            [styles.disabledTab]: disabled,
            [styles.tabSelected]: selectedTab === tab,
            [styles.menuOpen]: visibleMenu,
          },
          styles.tabButton,
          'text__button__medium__textNeutral30',
        )}
        onClick={onClickTab}
        id="album-tab-menu"
      >
        {tab}
        <ChevronIcon
          className={classnames(styles.chevronIcon, {
            [styles.menuOpen]: visibleMenu,
          })}
        />
      </div>
      {visibleMenu && showMenu()}
    </>
  );
};

export { AlbumTabMenu };
