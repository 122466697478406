import { useContext, useEffect, useState } from 'react';
import { Toggle } from 'common/toggle';
import { AppContext, appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { AlbumTabs, NotificationType } from 'common/enums';
import { Select } from 'common/select';
import { LabeledText } from 'common/labeled-text';
import { frequencyOptions } from 'config/constants';
import { classnames, isReadOnlySubOrRestrictedAlbum } from 'helpers/utils';
import { EditIcon, InfoIcon } from 'assets/icons';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { HelperText } from 'common/helper-text';
import styles from './reminders-section.module.scss';

type ReminderSectionProps = {
  album: AlbumType;
  handleTabChange: (tab: AlbumTabs) => void;
};

const nameAndEmail = (name: string, email: string) => `${name} (${email})`;

const searchUserName = (id: number, album: AlbumType) => {
  const user = album.contributors.find(
    (contributor) => contributor.invitationId === id,
  );
  if (user) {
    return nameAndEmail(user.name, user.email);
  }
  if (album.owner.id === id) {
    return nameAndEmail(album.owner.name, album.owner.email);
  }
  return '';
};

const createTheAlbumOptions = (album: AlbumType) => {
  const options = album.contributors.map(
    (contributor) =>
      ({
        id: contributor.invitationId,
        value: nameAndEmail(contributor.name, contributor.email),
      }) as SelectType,
  );
  options.unshift({
    id: 0,
    value: nameAndEmail(album.owner.name, album.owner.email),
  });

  return options;
};

const showFrequency = (frequency: string) => {
  const option = frequencyOptions.find(
    (opt: SelectType) => opt.id === frequency,
  );
  return option?.value;
};

const ReminderSection = ({ album, handleTabChange }: ReminderSectionProps) => {
  const { dispatch } = useContext(AppContext);
  const [remindersEnabled, setRemindersEnabled] = useState<boolean>(
    !!album.reminder,
  );
  const [editReminders, setEditReminders] = useState<boolean>(false);
  const [personToRemind, setPersonToRemind] = useState<SelectType | undefined>(
    undefined,
  );
  const [frequency, setFrequency] = useState<SelectType>(frequencyOptions[0]);
  const disabled = isReadOnlySubOrRestrictedAlbum(album);
  const albumReminder = album.reminder ? album.reminder : null;
  const [reminder, setReminder] = useState<ReminderType | null>(albumReminder);

  const handleSaveReminder = async () => {
    if (!personToRemind) {
      return;
    }

    const targetIsOwner = personToRemind.id === 0;
    const contributorTarget = album.contributors.find(
      (contributor) => contributor.invitationId === personToRemind.id,
    );
    const targetId =
      !targetIsOwner && contributorTarget?.invitationId
        ? contributorTarget.invitationId
        : null;
    try {
      const reminderToSave = {
        targetId,
        frequency: frequency.id,
        targetIsOwner,
      };
      let response;
      if (reminder) {
        response = await AlbumsController.editReminder(
          album.id,
          reminder.id,
          reminderToSave,
        );
      } else {
        response = await AlbumsController.createReminder(
          album.id,
          reminderToSave,
        );
      }
      setEditReminders(false);
      setReminder(response);
    } catch (e) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
    }
  };

  useEffect(() => {
    if (reminder) {
      const user = album.contributors.find(
        (contributor) => contributor.invitationId === reminder.targetId,
      );

      if (user) {
        setPersonToRemind({
          id: user.invitationId || 0,
          value: nameAndEmail(user.name, user.email),
        });
      } else {
        const isOwner = reminder.targetIsOwner;
        if (isOwner) {
          setPersonToRemind({
            // We do this to mark that there's no invitation ID that matches the owner.
            id: 0,
            value: nameAndEmail(album.owner.name, album.owner.email),
          });
        }
      }
      const frequencyOption = frequencyOptions.find(
        (opt: SelectType) => opt.id === reminder.frequency,
      );
      if (frequencyOption) {
        setFrequency(frequencyOption);
      }
    }
  }, [reminder]);

  const showInformation = () =>
    reminder && (
      <div className={styles.informationBox}>
        <div className={styles.information}>
          <LabeledText
            label="Remind"
            text={searchUserName(
              (personToRemind?.id as number) || album.owner.id,
              album,
            )}
          />
          <LabeledText
            label="to record"
            text={showFrequency(reminder.frequency)}
          />
        </div>
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryGhost}
          onClick={() => {
            setEditReminders(true);
          }}
          disabled={disabled}
        >
          <EditIcon className={styles.editIcon} />
        </Button>
      </div>
    );
  const showForm = () => (
    <div className={styles.form}>
      <div className={styles.formSelects}>
        <p className="text__body__semi__bold__medium__textNeutral40">Remind</p>
        <Select
          options={createTheAlbumOptions(album)}
          optionSelected={{
            id: personToRemind ? personToRemind.id : 0,
            value: personToRemind ? personToRemind.value : '',
          }}
          onClickOption={(option) => {
            setPersonToRemind(option);
          }}
          value={personToRemind?.value}
          id="personToRemind"
          placeholder="Select User"
          title=""
          className={styles.selectPerson}
          smallOptions
        />
        <p
          className={classnames(
            styles.selectLabel,
            'text__body__semi__bold__medium__textNeutral40',
          )}
        >
          to record
        </p>
        <Select
          options={frequencyOptions}
          optionSelected={{
            id: frequency.id,
            value: frequency.value,
          }}
          onClickOption={(option) => setFrequency(option)}
          value={frequency.value}
          id="frequency"
          title=""
          className={styles.selectFrequency}
          smallOptions
        />
      </div>
      <div className={styles.buttons}>
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryStroke}
          onClick={() => handleSaveReminder()}
          disabled={!personToRemind || disabled}
          className={styles.saveButton}
        >
          Save Changes
        </Button>
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryGhost}
          onClick={() => {
            setEditReminders(false);
            if (!reminder) {
              setRemindersEnabled(false);
            }
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
  const handleDeleteReminder = async () => {
    if (!reminder) {
      return;
    }
    try {
      await AlbumsController.deleteReminder(album.id, reminder.id);
      setReminder(null);
      setPersonToRemind(undefined);
      setFrequency(frequencyOptions[0]);
    } catch (e) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
    }
  };

  useEffect(() => {
    if (album.reminder) {
      setReminder(album.reminder);
    } else {
      setReminder(null);
      setRemindersEnabled(false);
      setPersonToRemind(undefined);
      setFrequency(frequencyOptions[0]);
    }
  }, [album]);

  const toggleChange = () => {
    if (remindersEnabled && reminder) {
      handleDeleteReminder();
    }
    setRemindersEnabled(!remindersEnabled);
    setEditReminders(true);
  };

  const handleSendSampleEmail = async () => {
    try {
      await AlbumsController.sendSampleEmail(album.id);
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Email sent',
          message: '',
          type: NotificationType.Success,
        }),
      });
    } catch (e: any) {
      const notQuestionToSend = e?.errors?.find(
        (err: any) => err.errorCode === 40501,
      );
      if (notQuestionToSend) {
        dispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Your interview script is empty',
            message: 'Add prompts on the add prompts tab to set up reminders.',
            type: NotificationType.Error,
          }),
        });
        return;
      }
      const ToMuchRequest = e?.errors?.find(
        (err: any) => err.errorCode === 42900,
      );
      if (ToMuchRequest) {
        dispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Too much emails sent',
            message: 'Please try again later',
            type: NotificationType.Error,
          }),
        });
        return;
      }
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
    }
  };

  return (
    <div className={classnames(globalStyles.infoCard, styles.reminderSection)}>
      <div className={styles.titleBox}>
        <p className="text__title2__textNeutral40">Record reminders</p>
        <HelperText
          content={
            <span className="text__body__regular__small__textNeutral30">
              You can set your album to automatically send email reminders to
              record. We will send them the first unanswered prompt on your{' '}
              <Button
                buttonSize={ButtonSize.Small}
                buttonStyle={ButtonStyle.PrimaryGhostLink}
                onClick={() => {
                  handleTabChange(AlbumTabs.addPrompts);
                }}
              >
                interview script
              </Button>
              . Click{' '}
              <Button
                buttonSize={ButtonSize.Small}
                buttonStyle={ButtonStyle.PrimaryGhostLink}
                onClick={() => {
                  handleSendSampleEmail();
                }}
              >
                here
              </Button>{' '}
              to send an sample email to yourself. Reminder emails go out on
              Monday mornings.
            </span>
          }
          icon={<InfoIcon className={styles.infoIcon} />}
        />
      </div>
      <div className={styles.toggleAndText}>
        <Toggle
          id="reminderToggle"
          checked={remindersEnabled}
          onChangeFn={() => toggleChange()}
          disabled={disabled}
          tooltipText="Upgrade the album to enable this feature."
        />
        <span className="text__body__regular__medium__textNeutral30">
          Email reminders
        </span>
      </div>
      {remindersEnabled && (editReminders ? showForm() : showInformation())}
    </div>
  );
};

export { ReminderSection };
