import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { ModalInfo } from 'common/modal-info';
import Bowser from 'bowser';
import { getFileExtension } from 'helpers/file-utils';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import styles from './video-clip.module.scss';
import { MobileVideoPlayer } from './components/mobile-video-player/mobile-video-player';

type VideoClipProps = {
  clipSelected: ClipType;
  autoPlaying?: boolean;
  notifyOnPlay?: () => void;
  notifyOnEnded?: () => void;
};

const VideoClip: React.FC<VideoClipProps> = ({
  clipSelected,
  autoPlaying = false,
  notifyOnEnded,
  notifyOnPlay,
}) => {
  const [hasError, setHasError] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const showVideoPlayer = () => (
    <ReactPlayer
      playing={autoPlaying}
      playsinline
      className={styles.videoClip}
      controls
      url={clipSelected.videoUrl}
      width=""
      height=""
      onPlay={notifyOnPlay}
      onEnded={notifyOnEnded}
      config={{
        file: {
          attributes: {
            disablePictureInPicture: true,
            controlsList: 'nodownload noplaybackrate',
            poster: clipSelected.thumbnailUrl,
          },
        },
      }}
      onError={() => {
        const { browser } = Bowser.parse(window.navigator.userAgent);
        if (
          browser.name === 'Safari' &&
          clipSelected.videoUrl &&
          getFileExtension(clipSelected.videoUrl) === '.webm'
        )
          setHasError(true);
      }}
    />
  );

  return (
    <>
      {hasError && (
        <ModalInfo
          onClose={() => setHasError(false)}
          info="Safari does not currently support .webm. We’re working on converting these clips to a different format. In the meantime you can access your clips through any other web browser."
          buttonMessage="Understood"
          title="Format not supported"
        />
      )}
      {isMobile ? (
        <MobileVideoPlayer
          clipSelected={clipSelected}
          notifyOnPlay={notifyOnPlay}
          notifyOnEnded={notifyOnEnded}
          notifyError={() => {
            setHasError(true);
          }}
        />
      ) : (
        showVideoPlayer()
      )}
    </>
  );
};

export { VideoClip };
